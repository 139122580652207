﻿export class ExpiredAuditWidgetModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.templateName = data.templateName;

            this.agency = data.agency;

            this.agencyId = data.agencyId;

            this.validFrom = data.validFrom;

            this.validUntil = data.validUntil;

            this.status = data.status;
        }
    }

    public id: number;

    public templateName: string;

    public agency: string;

    public agencyId: number;

    public validFrom: Date;

    public validUntil: Date;

    public status: string;

    "type"? = "ExpiredAuditWidgetModel";
}
