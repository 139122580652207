﻿import { BaseRule } from "./BaseRule";
import { IEqualsBoolQuestionRule } from "./QuestionRuleContracts";
import { IValueToCompare } from "./IValueToCompare";

export class EqualsBoolRule extends BaseRule implements IEqualsBoolQuestionRule, IValueToCompare<string> {
    constructor(data?: any) {
        super(data);

        if (data) {
            this.valueToCompare = data.valueToCompare;
        }
    }

    public valueToCompare: string;

    "type"? = "EqualsBoolRule";
}
