﻿import { BaseRule } from "./BaseRule";
import { IMoreThanOrEqualRule } from "./IMoreThanOrEqualRule";

export class MoreThanOrEqualRule extends BaseRule implements IMoreThanOrEqualRule {
    constructor(data?: any) {
        super(data);

        if (data) {
            this.valueToCompare = data.valueToCompare;
        }
    }

    public valueToCompare: number;

    "type"? = "MoreThanOrEqualRule";
}
