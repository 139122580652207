export enum RoleNames {
    SuperAdmin = "Super Admin",
    AgencyAdmin = "Agency Admin",
    AgencyAuditViewer = "Agency Audit Viewer",
    AgencyAuditor = "Agency Auditor",
    AgencyPersonnel = "Agency Personnel",
    HEIAdmin = "HEI Admin",
    HEIAuditViewer = "HEI Audit Viewer",
    HEIAuditor = "HEI Auditor",
    HEIStaff = "HEI Staff"
}
