﻿export class AuditModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;
            this.agencyId = data.agencyId;
            this.agencyName = data.agencyName;
            this.name = data.name;
            this.templateName = data.templateName;
            this.parentAgencyName = data.parentAgencyName;
            this.parentAgencyId = data.parentAgencyId;
            this.validFrom = data.validFrom;
            this.validUntil = data.validUntil;
            this.validOn = data.validOn;
            this.status = data.status;
            this.statusDisplayName = data.statusDisplayName;
            this.creationType = data.creationType;
            this.regionName = data.regionName;
        }
    }

    public id: number;

    public agencyId: number;

    public agencyName: string;

    public name: string;

    public templateName: string;

    public parentAgencyName: string;

    public parentAgencyId: number;

    public validFrom: Date;

    public validUntil: Date;

    public validOn: string;

    public status: number;

    public statusDisplayName: string;

    public creationType: string;

    public regionName: string;

    "type"? = "AuditModel";
}
