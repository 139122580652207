import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

export enum MenuItemType {
    link,
    action
}

export interface IMenuItem {
    menuType: MenuItemType;
    menuTitle: string;
}

export interface ILinkMenuItem extends IMenuItem {
    link: string;
    params: string;
}

export interface IActionMenuItem extends IMenuItem {
    func: () => void;
}

@Component({
    selector: 'action-menu',
    templateUrl: 'action-menu.component.html'
})

export class ActionMenuComponent {
    @Input() menuItems: Array<IMenuItem> = [];
    @Input() outline: boolean;
    left: string;
    top: string;
    constructor(private readonly router: Router) { }

    onItemClick(item: IMenuItem): void {
        if (item.menuType === MenuItemType.link) {
            const linkItem = item as ILinkMenuItem;
            if (linkItem.params) {
                this.router.navigate([linkItem.link, linkItem.params]);
            } else {
                this.router.navigate([linkItem.link]);
            }
        } else {
            const actionItem = item as IActionMenuItem;
            actionItem.func();
        }
    }

    dropdownClick(event: any): void {
        const buttonT = event.clientY + 10;
        const dropdownW = event.target.nextElementSibling.offsetWidth;
        const dropdownH = event.target.nextElementSibling.offsetHeight;

        if (dropdownH === 0) {
            this.top = 'auto';
        } else {
            this.top = ((buttonT + dropdownH) > window.innerHeight)
                ? `${buttonT - dropdownH - 20}px`
                : `${buttonT}px`;
        }

        if (dropdownW === 0) {
            this.left = 'auto';
        } else {
            this.left = ((event.clientX + dropdownW) > window.innerWidth)
                ? `${event.clientX - dropdownW}px`
                : `${event.clientX}px`;
        }
    }
}
