﻿import { AuditStatus } from "./AuditStatus";

export class ActionPlanDetailsModel {
    constructor(data?: any) {
        if (data) {
            this.auditId = data.auditId;

            this.agencyName = data.agencyName;

            this.agencyId = data.agencyId;

            this.status = data.status;
        }
    }

    public auditId: number;

    public agencyName: string;

    public agencyId: number;

    public status: AuditStatus;

    "type"? = "ActionPlanDetailsModel";
}
