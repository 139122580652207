﻿import { MessageType } from "./MessageType";

export class ResponseModel {
    constructor(data?: any) {
        if (data) {
            this.messageType = data.messageType;

            this.message = data.message;

            this.data = data.data;
        }
    }

    public messageType: MessageType;

    public message: string;

    public data: any;

    "type"? = "ResponseModel";
}
