import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
    transform(value: object, kind?: string): any {
        const keys = [];

        for (const key in value) {
            if (value.hasOwnProperty(key)) {
                /**
                 * Check if key is a number then convert it to number otherwise let it as is
                 * Kendo uses strict equality ===
                 * The === operator compares the values as well as the data types of the operands
                 */
                if (!isNaN(parseFloat(key)) && isFinite(+key)) {
                    keys.push({ key: +key, value: value[key] });
                } else {
                    keys.push({ key: key, value: value[key] });
                }
            }
        }

        return (kind === 'sortById')
            ? keys.sort((a, b) => a.key > b.key ? 1 : -1)
            : keys.sort((a, b) => a.value > b.value ? 1 : -1);
    }
}
