﻿import { AgencyModel } from "./AgencyModel";

export class AgencyDetailsModel extends AgencyModel {
    constructor(data?: any) {
        super(data);

        if (data) {
            this.address = data.address;

            this.phone = data.phone;

            this.website = data.website;

            this.regionId = data.regionId;

            this.disciplineIds = data.disciplineIds;
        }
    }
 
    public address: string;

    public phone: string;

    public website: string;

    public regionId: number;

    public disciplineIds: number[];

    "type"? = "AgencyDetailsModel";
}
