import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { HttpObserverService } from '../core/http-observer.service';

@Component({
    selector: 'spinner',
    templateUrl: 'spinner.component.html',
    styleUrls: ['spinner.component.css']
})
export class SpinnerComponent implements OnInit, OnDestroy {
    spinning = false;
    private execution = false;

    private subscription: Subscription;

    constructor(
        private readonly httpObserverService: HttpObserverService
    ) {
    }

    ngOnInit(): void {
        this.subscription = this.httpObserverService.isExecuting
            .subscribe(execution => {
                this.execution = execution;

                //another data request may start/stop execution
                setTimeout(() => this.spinning = this.execution, 200);
            });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
