﻿export class AddAgenciesModel {
    constructor(data?: any) {
        if (data) {
            this.heiId = data.heiId;

            this.agencyIds = data.agencyIds;
        }
    }

    public heiId: number;

    public agencyIds: number[];

    "type"? = "AddAgenciesModel";
}
