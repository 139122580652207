﻿export enum QuestionType {
    freeText = 0,
    singleSelect = 1,
    multiSelect = 2,
    range = 3,
    integer = 4,
    decimal = 5,
    date = 6,
    bool = 7
}
