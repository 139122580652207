import { BaseRule } from "./BaseRule";
import { IEqualsNumberQuestionRule } from "./QuestionRuleContracts";
import { IEqualsRule } from "./IEqualsRule";

export class EqualsNumberRule extends BaseRule implements IEqualsNumberQuestionRule, IEqualsRule<number> {
    constructor(data?: any) {
        super(data);

        if (data) {
            this.valueToCompare = data.valueToCompare;
        }
    }

    public valueToCompare: number;

    "type"? = "EqualsNumberRule";
}
