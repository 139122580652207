export class ErrorModel {
    constructor(data?: any) {
        if (data) {
            this.clientMessage = data.clientMessage;
        }
    }

    public clientMessage: string;

    "type"? = "ErrorModel";
}
