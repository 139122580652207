import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface IMessage {
    text: string;
    type: string;
}

@Injectable()
export class NotificationMessageService {
    message: Subject<IMessage> = new Subject();
    message$: Observable<IMessage>;

    constructor() {
        this.message$ = this.message.asObservable();
    }
}
