export class CreateHeiModel {
    constructor(data?: any) {
        if (data) {
            this.regionId = data.regionId;

            this.id = data.id;

            this.name = data.name;

            this.address = data.address;

            this.phone = data.phone;

            this.webSite = data.webSite;
        }
    }

    public regionId: number;

    public id: number;

    public name: string;

    public address: string;

    public phone: string;

    public webSite: string;

    "type"? = "CreateHeiModel";
}
