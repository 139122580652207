export class FilterField<TFilter> {
    title: string;
    filterName: string;
    filter: TFilter;

    "type"? = "FilterField";

    constructor(data?: any) {
        if (data) {
            this.title = data.title;
            this.filterName = data.filterName;
            this.filter = data.filter;
        }
    }
}
