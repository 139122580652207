﻿export class RegionModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.name = data.name;

            this.isActive = data.isActive;

            this.status = data.status;
        }
    }

    public id: number;

    public name: string;

    public isActive: boolean;

    public status: string;

    "type"? = "RegionModel";
}
