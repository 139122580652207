export class ForgotPasswordRequestModel {
    constructor(data?: any) {
        if (data) {
            this.email = data.email;
        }
    }

    public email: string;

    "type"? = "ForgotPasswordRequestModel";
}
