import { BaseQuestion } from "./BaseQuestion";

import { IRangeRestriction } from "./IRangeRestriction";

export class NumericQuestion<T> extends BaseQuestion implements IRangeRestriction<T> {
    constructor(data?: any) {
        super(data);

        if (data) {
            this.minValue = data.minValue;

            this.maxValue = data.maxValue;
        }
    }

    public minValue: T;

    public maxValue: T;

    "type"? = "NumericQuestion";
}
