import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigProvider, InPlaceCoreModule } from '@inplace/core';
import { InPlaceHttpModule } from '@inplace/http';
import { InPlaceStorageModule } from '@inplace/storage';
import { NgProgressModule } from '@ngx-progressbar/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { CoreModule } from './core/core.module';
import { AuditToolConfigurationService } from './core/services/audit-tool-configuration.service';
import { SharedModule } from './shared/shared.module';

import './core/extensions';

@NgModule({
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AuditToolConfigurationService],
            multi: true
        },
        CookieService,
        ConfigProvider
    ],
    declarations: [AppComponent, AuthComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        NgProgressModule,
        ToastrModule.forRoot(),
        InPlaceCoreModule.forRoot(),
        InPlaceHttpModule.forRoot(),
        InPlaceStorageModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function initializeApp(appConfig: AuditToolConfigurationService): any {
    return () => appConfig.load();
}
