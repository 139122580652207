﻿import { IInStringListQuestionRule } from "./QuestionRuleContracts";
import { SelectableQuestion } from "./SelectableQuestion";

export class MultipleSelectQuestion extends SelectableQuestion implements IInStringListQuestionRule {
    constructor(data?: any) {
        super(data);

        if (data) {
        }
    }

    "type"? = "MultipleSelectQuestion";
}
