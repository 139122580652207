export class UserRegistrationModel {
    'type'? = 'UserRegistrationModel';

    name: string;
    surname: string;
    email: string;
    roleId: string;
    phoneNumber: string;
    businessPhone: string;
    userType: number;

    constructor(data?: any) {
        if (data) {
            this.name = data.name;
            this.surname = data.surname;
            this.email = data.email;
            this.roleId = data.roleId;
            this.phoneNumber = data.phoneNumber;
            this.businessPhone = data.businessPhone;
            this.userType = data.userType;
        }
    }
}
