export class RefreshGrantModel {
    constructor(data?: any) {
        if (data) {
            this.refresh_token = data.refresh_token;
        }
    }

    public refresh_token: string;

    "type"? = "RefreshGrantModel";
}
