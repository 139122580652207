export class ScheduleAuditModel {
    constructor(data?: any) {
        if (data) {
            this.auditTemplateId = data.auditTemplateId;

            this.auditTemplateName = data.auditTemplateName;

            this.agencyId = data.agencyId;

            this.validFrom = data.validFrom;

            this.validTo = data.validTo;
        }
    }

    public auditTemplateId: number;

    public auditTemplateName: string;

    public agencyId: number;

    public validFrom: Date;

    public validTo: Date;

    "type"? = "ScheduleAuditModel";
}
