import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ConfigProvider } from 'src/app/core/services/config-provider.service';

@Injectable()
export class UploadService {
    constructor(
        private readonly httpClient: HttpClient, /** this is need to for getBlob */
        private readonly configProvider: ConfigProvider
    ) {
    }

    uploadFile(url: string, data: object): Observable<string> {
        let headers = this.configProvider.setHeaders(null, null);
        headers = headers.append('enctype', 'multipart/form-data');

        return this.httpClient.post(url, data, { headers, responseType: 'text' });
    }

    downloadFile(url: string, params: HttpParams, fileName: string): void {
        const headers = this.configProvider.setHeaders();
        this.httpClient.get(url, { headers, params, responseType: 'blob' }).subscribe(data => {
            const blob = new Blob([data], { type: 'application/octet-stream' });
            if (!navigator.msSaveOrOpenBlob) {
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = objectUrl;
                a.download = fileName;
                a.click();

                URL.revokeObjectURL(objectUrl);
                a.remove();
            } else { // browser IE 11
                navigator.msSaveOrOpenBlob(blob, fileName);
            }
        });
    }

    removeFile(url: string, filePath: string, fileUploadId: number): Subscription {
        let params = new HttpParams({
            fromObject: {
                filePath
            }
        });
        if (fileUploadId) {
            params = params.append('id', fileUploadId.toString());
        }

        const headers = this.configProvider.setHeaders();
        return this.httpClient.delete(url, { headers, params }).subscribe();
    }
}
