import { Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { Constants } from '../core/constants';
import { PermissionsService } from '../permissions/permissions.service';

@Component({
    selector: 'side-bar',
    templateUrl: 'side-bar.component.html',
    styleUrls: ['side-bar.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SideBarComponent implements OnInit {
    menuItems: Array<IMenuItem>;
    allMenuItems: Array<IMenuItem>;

    @HostBinding('class.sidebar-open') isOpen = false;

    constructor(
        private readonly router: Router,
        private readonly constants: Constants,
        private readonly permissionsService: PermissionsService) {
    }

    ngOnInit(): void {
        this.menuItems = [
            {
                title: 'Home',
                icon: 'home',
                link: this.constants.angularRoutes.dashboardUrl
            }
        ];

        this.permissionsService.fetchCurrentUserPermissions()
            .subscribe((permissions: { [key: number]: number; }) => {
                this.allMenuItems = [
                    {
                        title: 'Home',
                        icon: 'home',
                        link: this.constants.angularRoutes.dashboardUrl
                    },
                    {
                        title: 'Audit',
                        icon: 'settings',
                        children: [
                            {
                                title: 'Audits',
                                // icon: "event_note",
                                icon: '',
                                areaId: 2,
                                permission: 0,
                                link: this.constants.angularRoutes.staffAuditsUrl
                            },
                            {
                                title: 'Upcoming Audits',
                                // icon: "move_to_inbox",
                                icon: '',
                                areaId: 2,
                                permission: 0,
                                link: this.constants.angularRoutes.staffAuditsUpcomingUrl
                            },
                            {
                                title: 'Audit Status Dashboard',
                                icon: '',
                                areaId: 2,
                                permission: 0,
                                link: this.constants.angularRoutes.staffAuditReportsUrl
                            },
                            {
                                title: 'Templates',
                                // icon: "content_paste",
                                icon: '',
                                areaId: 7,
                                permission: 0,
                                link: this.constants.angularRoutes.staffAuditTemplateUrl
                            },
                            {
                                title: 'Template Builder',
                                // icon: "content_paste",
                                icon: '',
                                areaId: 7,
                                permission: 2,
                                link: this.constants.angularRoutes.templateBuilderUrl
                            }
                        ]
                    },
                    {
                        title: 'Agency',
                        icon: 'business',
                        children: [
                            {
                                title: 'Agencies',
                                // icon: "account_balance_wallet",
                                icon: '',
                                areaId: 1,
                                permission: 0,
                                link: this.constants.angularRoutes.staffAgenciesUrl
                            },
                            {
                                title: 'Users',
                                // icon: "people",
                                icon: '',
                                areaId: 9,
                                permission: 0,
                                link: this.constants.angularRoutes.staffAgencyAuditorsUrl
                            }
                        ]
                    },
                    {
                        title: 'HEI',
                        icon: 'school',
                        children: [
                            {
                                title: 'HEIs',
                                // icon: "school",
                                icon: '',
                                areaId: 4,
                                permission: 0,
                                link: this.constants.angularRoutes.staffHeiUrl
                            },
                            {
                                title: 'Users',
                                // icon: "people",
                                icon: '',
                                areaId: 10,
                                permission: 0,
                                link: this.constants.angularRoutes.staffHeiAuditorsUrl
                            }
                        ]
                    },
                    {
                        title: 'Admin',
                        icon: 'lock',
                        children: [
                            {
                                title: 'Disciplines',
                                // icon: "class",
                                icon: '',
                                areaId: 3,
                                permission: 0,
                                link: this.constants.angularRoutes.adminDisciplineListUrl
                            },
                            {
                                title: 'Regions',
                                // icon: "location_city",
                                icon: '',
                                areaId: 5,
                                permission: 0,
                                link: this.constants.angularRoutes.adminRegionListUrl
                            },
                            {
                                title: 'Users',
                                // icon: "people",
                                icon: '',
                                areaId: 8,
                                permission: 0,
                                link: this.constants.angularRoutes.adminUsersUrl
                            },
                            {
                                title: 'Roles',
                                // icon: "security",
                                icon: '',
                                areaId: 6,
                                permission: 0,
                                link: this.constants.angularRoutes.adminRolesUrl
                            },
                            {
                                title: 'Privacy Management',
                                // icon: "security",
                                icon: '',
                                areaId: 11,
                                permission: 0,
                                link: this.constants.angularRoutes.adminPrivacyManagementUrl
                            }
                        ]
                    }
                ];
                for (const item of this.allMenuItems) {
                    if (item.children != null) {
                        item.children = item.children.filter(filter => permissions.hasOwnProperty(filter.areaId) &&
                            permissions[filter.areaId] >= filter.permission);
                        if (item.children.length > 0) {
                            this.menuItems.push(item);
                        }
                    }
                }
            });
    }

    getArrowIconName(isChildenVisible: boolean): string {
        return isChildenVisible ? 'arrow_drop_up' : 'arrow_drop_down';
    }

    changeChildrenItems(menuItemIndex: number, isChildenVisible: boolean): void {
        this.menuItems[menuItemIndex].isChildrenVisible = !isChildenVisible;
    }

    isCollapsedMenu(menuItem: IMenuItem): boolean {
        return !this.isOpen && this.searchLink(menuItem);
    }

    toggle(): void {
        if (this.isOpen) {
            this.collapseAllMenu(this.menuItems);
        } else {
            this.expandActiveMenu(this.menuItems);
        }

        this.isOpen = !this.isOpen;
    }

    private collapseAllMenu(menuItems: Array<IMenuItem>): void {
        for (const menuItem of this.menuItems) {
            menuItem.isChildrenVisible = false;
        }
    }

    private expandActiveMenu(menuItems: Array<IMenuItem>): void {
        for (const topLevelMenuItem of menuItems) {
            if (this.searchLink(topLevelMenuItem)) {
                topLevelMenuItem.isChildrenVisible = true;
                break;
            }
        }
    }

    private searchLink(menuItem: IMenuItem): boolean {
        for (const key in menuItem) {
            if (menuItem.hasOwnProperty(key)) {
                if (key === 'link' && menuItem[key] === this.router.url) {
                    return true;
                } else if (key === 'children' && typeof menuItem[key] === 'object') {
                    for (const item of menuItem[key]) {
                        if (this.searchLink(item)) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }
}

export interface IMenuItem {
    title: string;
    icon: string;
    isChildrenVisible?: boolean;
    link?: string;
    children?: Array<IMenuItem>;
    areaId?: number;
    permission?: number;
}
