import { Injectable } from '@angular/core';
import { HttpClientService } from '@inplace/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { HttpResponseHandlerService } from '../core/http-response-handler.service';
import { ConfigProvider } from '../core/services/config-provider.service';
import { Models } from '../models';

@Injectable()
export class PermissionsService {
    private userPermissions: { [key: number]: number; };

    constructor(
        private readonly httpClient: HttpClientService,
        private readonly httpResponseHandlerService: HttpResponseHandlerService,
        private readonly configProvider: ConfigProvider) {
    }

    rolePermissions(roleId: string): Observable<Models.PermissionsMatrixModel> {
        const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/permissions/${roleId}`
        return this.httpClient.get<Models.PermissionsMatrixModel>(url);
    }

    updateRolePermissions(roleId: string, rolePermissions: Array<Models.PermissionItemModel>): Observable<Object> {
        const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/permissions/${roleId}`;
        return this.httpClient.put(url, rolePermissions).pipe(
            tap((response: Models.SuccessModel) => {
                this.httpResponseHandlerService.handleSuccess(response);
            })
        );
    }

    fetchCurrentUserPermissions(): Observable<Object> {
        if (this.userPermissions) {
            return of(this.userPermissions);
        } else {
            const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/permissions`;
            return this.httpClient.get(url).pipe(
                tap((permissions: { [key: number]: number; }) => {
                    this.userPermissions = permissions;
                })
            );
        }
    }

    hasAccess(area: number, permission: number): Observable<boolean> {
        return this.fetchCurrentUserPermissions().pipe(
            map((permissions: { [key: number]: number; }) => permissions.hasOwnProperty(area) && permissions[area] >= permission)
        );
    }

    clean(): void {
        this.userPermissions = null;
    }
}
