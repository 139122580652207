export class GridListModel<T> {
    constructor(data?: any) {
        if (data) {
            this.list = data.list;

            this.total = data.total;
        }
    }

    public list: T[];

    public total: number;

    "type"? = "GridListModel";
}
