export class AddAuditorsModel {
    constructor(data?: any) {
        if (data) {
            this.employerId = data.employerId;

            this.auditorIds = data.auditorIds;
        }
    }

    public employerId: number;

    public auditorIds: string[];

    "type"? = "AddAuditorsModel";
}
