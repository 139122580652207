﻿import { SelectableQuestion } from "./SelectableQuestion";
import { IEqualsStringQuestionRule, IInStringListQuestionRule } from "./QuestionRuleContracts";

export class SingleSelectQuestion extends SelectableQuestion implements IEqualsStringQuestionRule, IInStringListQuestionRule {
    constructor(data?: any) {
        super(data);

        if (data) {
        }
    }

    "type"? = "SingleSelectQuestion";
}
