import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { UploadModule } from '@progress/kendo-angular-upload';

import { ResponseInterceptor } from '../core/response.interceptor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ActionMenuComponent } from './action-menu.component';
import { AsyncValidatorDirective } from './async-validator.directive';
import { BreadcrumbComponent } from './breadcrumb.component';
import { BreadcrumbsService } from './breadcrumbs.service';
import { CamelCaseToRegularPipe } from './camel-case-to-regular.pipe';
import { ConfigurationService } from './configuration.service';
import { DateRangeValidatorDirective } from './date-range-validator.directive';
import { LoadImageDirective } from './directives/load-image.directive';
import { EqualValidatorDirective } from './equal-validator.directive';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { KeysPipe } from './keys.pipe';
import { LocalDatePipe } from './local-date.pipe';
import { ModalDialogService } from './modal-dialog.service';
import { NavigationBarComponent } from './navigation-bar.component';
import { NavigationMenuComponent } from './navigation-menu.component';
import { NotificationsDirective } from './notifications.directive';
import { NumberRangeValidatorDirective } from './number-range-validator.directive';
import { SideBarComponent } from './side-bar.component';
import { SpinnerComponent } from './spinner.component';
import { UploadComponent } from './upload/upload.component';
import { UploadService } from './upload/upload.service';
import { UserService } from './user.service';
import { UtilsService } from './utils.service';
import { WindowRef } from './windowref';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        DialogModule,
        ButtonsModule,
        UploadModule,
        ImageCropperModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
        UserService,
        ConfigurationService,
        UtilsService,
        ModalDialogService,
        BreadcrumbsService,
        CamelCaseToRegularPipe,
        WindowRef,
        UploadService,
        KeysPipe,
        LoadImageDirective
    ],
    declarations: [
        EqualValidatorDirective,
        DateRangeValidatorDirective,
        NumberRangeValidatorDirective,
        NotificationsDirective,
        KeysPipe,
        LocalDatePipe,
        CamelCaseToRegularPipe,
        BreadcrumbComponent,
        ActionMenuComponent,
        NavigationBarComponent,
        NavigationMenuComponent,
        SideBarComponent,
        AsyncValidatorDirective,
        UploadComponent,
        SpinnerComponent,
        ImageCropperComponent,
        LoadImageDirective
    ],
    exports: [
        EqualValidatorDirective,
        DateRangeValidatorDirective,
        NumberRangeValidatorDirective,
        NotificationsDirective,
        KeysPipe,
        LocalDatePipe,
        CamelCaseToRegularPipe,
        BreadcrumbComponent,
        ActionMenuComponent,
        NavigationBarComponent,
        NavigationMenuComponent,
        SideBarComponent,
        AsyncValidatorDirective,
        UploadComponent,
        SpinnerComponent,
        ImageCropperComponent,
        LoadImageDirective
    ],
    entryComponents: [
        ImageCropperComponent
    ]
})
export class SharedModule {
}
