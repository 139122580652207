export class ClientOptions {
    constructor(data?: any) {
        if (data) {
            this.pageSize = data.pageSize;

            this.userTypeRoles = data.userTypeRoles;

            this.questionRules = data.questionRules;
        }
    }

    public pageSize: number;

    public userTypeRoles: { [key: number]: { [key: string]: string } };

    public questionRules: { [key: number]: number[] };

    "type"? = "ClientOptions";
}
