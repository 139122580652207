import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { Constants } from '../core/constants';

import { UtilsService } from './utils.service';

@Pipe({ name: 'localDate' })
export class LocalDatePipe implements PipeTransform {
    private dateFormat: string;

    constructor(
        private readonly utilsService: UtilsService,
        private readonly constants: Constants
    ) {
        this.dateFormat = this.utilsService.getCurrentUserDateFormat().toUpperCase();
    }

    transform(value: moment.MomentInput, format: string): string {
        if (!value) {
            return '';
        }

        switch (format) {
            case 'cropped':
                return this.utilsService.toUtcString(value, this.dateFormat);
            case 'dateTime':
            default:
                return this.utilsService.toLocalDateString(value, `${this.dateFormat} ${this.constants.dateFormats.time24h}`);
        }
    }
}
