import { UserDetailsSubmitModel } from './UserDetailsSubmitModel';

export class UserViewModel {
    'type'? = 'UserViewModel';

    user: UserDetailsSubmitModel;
    titles: { [key: number]: string };
    cultures: { [key: number]: string };
    userTypes: { [key: number]: string };
    adminAgencies: { [key: number]: string };
    heis: { [key: number]: string };
    subscriptionsCount: number;

    constructor(data?: any) {
        if (data) {
            this.user = data.user;
            this.titles = data.titles;
            this.cultures = data.cultures;
            this.userTypes = data.userTypes;
            this.adminAgencies = data.adminAgencies;
            this.heis = data.heis;
            this.subscriptionsCount = data.subscriptionsCount;
        }
    }
}
