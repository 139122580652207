import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@inplace/http';
import { empty, from, Observable, throwError } from 'rxjs';

import { Constants } from '../core/constants';
import { IMessage, NotificationMessageService } from '../core/notification-message.service';
import { Models } from '../models';

@Injectable()
export class HttpResponseHandlerService {
    constructor(
        private router: Router,
        private notificationMessageService: NotificationMessageService,
        private constants: Constants,
        private authService: AuthService) { }

    handleError(error: any): Observable<any> {
        if (this.isUnauthorized(error.status)) {
            this.authService.authorize();
        } else if (this.isServerError(error.status)) {
            return from(this.router.navigate([this.constants.angularRoutes.internalServerErrorPath]));
        } else if (this.isNotFound(error.status)) {
            return from(this.router.navigate([this.constants.angularRoutes.notFoundErrorPath]));
        } else if (this.isForbidRequest(error.status)) {
            return from(this.router.navigate([this.constants.angularRoutes.forbidErrorPath]));
        } else if (this.isBadRequest(error.status)) {
            const responseBody = error.error;

            if (responseBody) {
                if (responseBody.clientMessage) {
                    const message: IMessage = {
                        text: responseBody.clientMessage,
                        type: Models.MessageType[Models.MessageType.error]
                    };
                    this.notificationMessageService.message.next(message);
                } else if (responseBody instanceof Blob && responseBody.type === 'application/json') {
                    const reader = new FileReader();
                    const service = this.notificationMessageService;
                    reader.onload = e => {
                        const parsedObject = JSON.parse(e.target.result as string);
                        const message: IMessage = {
                            text: parsedObject.clientMessage,
                            type: Models.MessageType[Models.MessageType.error]
                        };
                        service.message.next(message);
                    };
                    reader.readAsText(responseBody);
                }
            } else {
                if (error) {
                    this.notificationMessageService.message.next(error);
                }
            }

            return empty();
        }

        return throwError(error);
    }

    handleSuccess(result: Models.SuccessModel, redirectUrl?: string, messageType?: Models.MessageType): Observable<any> {
        const message: IMessage = {
            text: result.message,
            type: messageType
                ? Models.MessageType[messageType]
                : Models.MessageType[Models.MessageType.success]
        };

        this.notificationMessageService.message.next(message);

        if (redirectUrl) {
            return from(this.router.navigate([redirectUrl]));
        }

        return empty();
    }

    handleResponse(result: Models.ResponseModel, redirectUrl?: string): Observable<any> {
        const message: IMessage = {
            text: result.message,
            type: Models.MessageType[result.messageType]
        };

        this.notificationMessageService.message.next(message);

        if (redirectUrl) {
            return from(this.router.navigate([redirectUrl]));
        }

        return empty();
    }

    private isUnauthorized(status: number): boolean {
        return status === undefined || status === 0 || status === 401;
    }

    private isServerError(status: number): boolean {
        return status === 500;
    }

    private isNotFound(status: number): boolean {
        return status === 404;
    }

    private isBadRequest(status: number): boolean {
        return status === 400;
    }

    private isForbidRequest(status: number): boolean {
        return status === 403;
    }
}
