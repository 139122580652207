import { BaseRule } from "./BaseRule";
import { IInStringListQuestionRule } from "./QuestionRuleContracts";
import { IInListRule } from "./IInListRule";

export class InStringListRule extends BaseRule implements IInStringListQuestionRule, IInListRule<string> {
    "type"? = "InStringListRule";

    constructor(data?: any) {
        super(data);

        if (data) {
            this.variants = data.variants;
        }
    }

    public variants: string[];
}
