export class AddHeisModel {
    constructor(data?: any) {
        if (data) {
            this.agencyId = data.agencyId;

            this.heiIds = data.heiIds;
        }
    }

    public agencyId: number;

    public heiIds: number[];

    "type"? = "AddHeisModel";
}
