export class StringPostModel {
    constructor(data?: any) {
        if (data) {
            this.value = data.value;
        }
    }

    public value: string;

    "type"? = "StringPostModel";
}
