﻿export enum RuleType {
    containsTerm = 0,
    dateTimeRange = 1,
    lessThanOrEqual = 2,
    lessThan = 3,
    moreThan = 4,
    moreThanOrEqual = 5,
    numericRange = 6,
    equalsNumber = 7,
    equalsString = 8,
    numericValueIsInList = 9,
    stringValueIsInList = 10,
    equalsBool = 11
}
