import { NumericQuestion } from "./NumericQuestion";

import {
    INumericRangeQuestionRule,
    IMoreThanOrEqualQuestionRule,
    ILessThanOrEqualQuestionRule,
    IEqualsNumberQuestionRule
} from "./QuestionRuleContracts";

export class DecimalQuestion extends NumericQuestion<number>
    implements INumericRangeQuestionRule, IMoreThanOrEqualQuestionRule, ILessThanOrEqualQuestionRule, IEqualsNumberQuestionRule {
    constructor(data?: any) {
        super(data);

        if (data) {
        }
    }

    "type"? = "DecimalQuestion";
}
