import { AfterContentInit, Component, Input, ViewEncapsulation } from '@angular/core';

import { AuthService } from '../auth/auth.service';
import { Constants } from '../core/constants';
import { SideBarComponent } from './side-bar.component';
import { WindowRef } from './windowref';

@Component({
    selector: 'navigation-bar',
    templateUrl: 'navigation-bar.component.html',
    styleUrls: ['navigation-bar.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NavigationBarComponent implements AfterContentInit {
    @Input() sideBar: SideBarComponent;

    readonly userName: string = '';

    constructor(
        private readonly windowRef: WindowRef,
        readonly constants: Constants,
        private readonly authService: AuthService) {
        this.userName = authService.getCurrentUserName();
    }

    ngAfterContentInit(): void {
        if (this.windowRef.getWindow().innerWidth > 1024) {
            this.sideBar.toggle();
        }
    }

    logOff(): void {
        this.authService.logout();;
    }

    navIconsClick(): void {
        this.sideBar.toggle();
    }
}
