import { Answer } from "./Answer";
import { ISection } from "./ISection";
import { BaseQuestion } from "./BaseQuestion";

export class AnswerSection implements ISection {
    sectionId: number;
    orderId: number;
    title: string;
    description: string;
    answers: Array<Answer<BaseQuestion>>;

    "type"? = "AnswerSection";

    constructor(data?: any) {
        if (data) {
            this.sectionId = data.sectionId;
            this.orderId = data.orderId;
            this.title = data.title;
            this.description = data.description;
            this.answers = data.answers;
        }
    }
}
