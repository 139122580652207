﻿import { BaseRule } from "./BaseRule";
import { IContainsTermQuestionRule } from "./QuestionRuleContracts";
import { IContainsTermRule } from "./IContainsTermRule";

export class ContainsTermRule extends BaseRule implements IContainsTermQuestionRule, IContainsTermRule {
    constructor(data?: any) {
        super(data);

        if (data) {
            this.valueToCompare = data.valueToCompare;
        }
    }

    public valueToCompare: string;

    "type"? = "ContainsTermRule";
}
