﻿export class ActionPlanCommentModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.comment = data.comment;
        }
    }

    public id: number;

    public comment: string;

    "type"? = "ActionPlanCommentModel";
}
