import { HttpClient } from '@angular/common/http';
import { Directive, HostBinding, Input, OnChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ConfigProvider } from 'src/app/core/services/config-provider.service';
import { CacheService } from '../cache/cache.service';

@Directive({
    selector: '[loadImage]'
})
export class LoadImageDirective implements OnChanges {
    @HostBinding('attr.src') src: string;
    @Input('loadImage') image: { id: string, url: string };
    @Input('previewImage') previewImage: string;
    @Input('defaultImage') defaultImage = '/assets/img/no-logo-uploaded.png';

    constructor(
        private readonly httpClient: HttpClient, /** this is need to for getBlob */
        private readonly imageCache: CacheService,
        private readonly configProvider: ConfigProvider
    ) {}

    ngOnChanges(): void {
        this.loadImage();
    }

    private loadImage(): void {
        if (this.previewImage) {
            this.src = this.previewImage;

            return;
        }

        if (!this.image.id || this.image.id === '00000000-0000-0000-0000-000000000000') {
            this.src = this.defaultImage;

            return;
        }

        if (this.image.id && this.image.url) {
            this.src = this.image.url;

            return;
        }
        const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/media?id=${this.image.id}`;
        const headers = this.configProvider.setHeaders();
        this.imageCache.get(this.image.id,
            this.httpClient.get(url, { headers, responseType: 'blob' })
            .pipe(
                switchMap(response => new Observable<string>(observer => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        observer.next(reader.result as string);
                        observer.complete();
                    };
                    reader.readAsDataURL(response);
                })),
                catchError(() => of(this.defaultImage))
            )).subscribe(cachedImage => this.src = cachedImage);
    }
}
