import { Component, OnInit } from '@angular/core';
import { NgProgress } from '@ngx-progressbar/core';

@Component({
    selector: 'audit-tool-app',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

    constructor(
        private readonly progressBar: NgProgress
    ) {
    }

    ngOnInit(): void {
        this.progressBar.ref().start();
    }
}
