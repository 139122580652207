﻿import { BaseRule } from "./BaseRule";
import { IMoreThanRule } from "./IMoreThanRule";

export class MoreThanRule extends BaseRule implements IMoreThanRule {
    constructor(data?: any) {
        super(data);

        if (data) {
            this.valueToCompare = data.valueToCompare;
        }
    }

    public valueToCompare: number;

    "type"? = "MoreThanRule";
}
