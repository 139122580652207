import { Injectable } from '@angular/core';
import { AuthService as InPlaceAuthService } from '@inplace/http';
import { CookieService } from 'ngx-cookie-service';

import { Constants } from '../core/constants';
import { UserType } from '../models/UserType';
import { AuthTokenService } from './auth-token.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private readonly authTokenService: AuthTokenService,
        private readonly constants: Constants,
        private readonly cookieService: CookieService,
        private readonly authService: InPlaceAuthService
    ) { }

    logout(): void {
        this.authService.logoff();
    }

    isCurrentUserAdmin(): boolean {
        const userType = +this.authTokenService.getTokenProperty('type');

        return userType === UserType.admin && this.authTokenService.isAuthorized;
    }

    getCurrentUserName(): string {
        return this.authTokenService.getTokenProperty(this.constants.auth.tokenProperties.name);
    }

    getCurrentUserId(): string {
        return this.authTokenService.getTokenProperty(this.constants.auth.tokenProperties.id);
    }

    getCurrentUserCulture(): string {
        const cookieStr = this.cookieService.get(this.constants.auth.cultureCookieName); // example: c=en-GB|uic=en-GB
        if (!cookieStr) {
            return null;
        }

        const cultureStr = cookieStr.split('|')[0];
        const culture = cultureStr.split('=')[1];

        return culture;
    }
}
