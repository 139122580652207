import { UserRegistrationModel } from "./UserRegistrationModel";

export class HeiUserRegistrationModel extends UserRegistrationModel {
    "type"? = "HeiUserRegistrationModel";

    constructor(data?: any) {
        super(data);

        if (data) {
            this.heiId = data.heiId;
        }
    }

    public heiId: number;
}
