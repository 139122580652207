import { AgencyModel } from "./AgencyModel";
import { HeiModel } from "./HeiModel";

export class AgencyHeiModel {
    constructor(data?: any) {
        if (data) {
            this.agencyId = data.agencyId;

            this.agency = data.agency;

            this.heiId = data.heiId;

            this.hei = data.hei;
        }
    }

    public agencyId: number;

    public agency: AgencyModel;

    public heiId: number;

    public hei: HeiModel;

    "type"? = "AgencyHeiModel";
}
