import { BaseQuestion } from "./BaseQuestion";

export class SelectableQuestion extends BaseQuestion {
    constructor(data?: any) {
        super(data);

        if (data) {
            this.options = data.options;
        }
    }

    public options: string[];

    "type"? = "SelectableQuestion";
}
