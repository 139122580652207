﻿import { DisciplineApiModel } from "./DisciplineApiModel";

export class AuditApiModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.auditTemplateName = data.auditTemplateName;

            this.nextAuditDate = data.nextAuditDate;

            this.validFrom = data.validFrom;

            this.validUntil = data.validUntil;

            this.statusId = data.statusId;

            this.statusName = data.statusName;

            this.disciplines = data.disciplines;

            this.signedByHei = data.signedByHei;

            this.signedByAgency = data.signedByAgency;

            this.hasActionPlans = data.hasActionPlans;

            this.highPriorityItemsCount = data.highPriorityItemsCount;

            this.mediumPriorityItemsCount = data.mediumPriorityItemsCount;

            this.lowPriorityItemsCount = data.lowPriorityItemsCount;

            this.linkToAudit = data.linkToAudit;
        }
    }

    public id: number;

    public auditTemplateName: string;

    public nextAuditDate: Date;

    public validFrom: Date;

    public validUntil: Date;

    public statusId: number;

    public statusName: string;

    public disciplines: Array<DisciplineApiModel>;

    public signedByHei: string;

    public signedByAgency: string;

    public hasActionPlans: boolean;

    public highPriorityItemsCount: number;

    public mediumPriorityItemsCount: number;

    public lowPriorityItemsCount: number;

    public linkToAudit: string;

    "type"? = "AuditApiModel";
}
