export class ColumnField {
    constructor(data?: any) {
        if (data) {
            this.field = data.field;

            this.sortingField = data.sortingField;

            this.title = data.title;

            this.sortable = data.sortable;
        }
    }

    public field: string;

    public sortingField: string;

    public title: string;

    public sortable: boolean;

    "type"? = "ColumnField";
}
