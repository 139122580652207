import { Injectable } from '@angular/core';
import { AuthService } from '@inplace/http';
import { Subscription } from 'rxjs';

import { Constants } from '../core/constants';

@Injectable({
    providedIn: 'root'
})
export class AuthTokenService {

    refreshSubscription: Subscription;

    constructor(
        private readonly constants: Constants,
        private readonly auth: AuthService) { }


    deleteToken(): void {
        localStorage.removeItem(this.constants.auth.tokenKey);
        localStorage.removeItem(this.constants.auth.refreshTokenKey);
        localStorage.removeItem(this.constants.auth.expiresInKey);
    }

    unsubscribeRefresh(): void {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    }

    getTokenProperty(propertyName: string): string {
        const token = this.auth.token;
        if (token) {
            return this.decodeToken(token)[propertyName];
        } else {
            return '';
        }
    }


    get isAuthorized(): boolean {
        if (this.isTokenExpired()) {
            return false;
        }
        return true;
    }

    isTokenExpired(offsetSeconds?: number): boolean {
        if (!this.auth.token) {
            return true;
        }
        const date = this.getTokenExpirationDate();
        offsetSeconds = offsetSeconds || 0;

        if (date === null) {
            return true;
        }

        return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
    }

    private getTokenExpirationDate(): Date | null {
        let decoded: any;
        decoded = this.decodeToken(this.auth.token);

        if (!decoded.hasOwnProperty('exp')) {
            return null;
        }

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);

        return date;
    }

    private decodeToken(token: string): any {
        let data = {};
        if (token) {
            const encoded = token.split('.')[1];
            data = JSON.parse(this.base64Decode(encoded));
        }
        return data;
    }

    private base64Decode(str: string) {
        let output = str.replace('-', '+').replace('_', '/');
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += '==';
                break;
            case 3:
                output += '=';
                break;
            default:
                throw new Error('Illegal base64url string!');
        }
        return window.atob(output);
    }

}
