import { Component, Input, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BreadcrumbsService, PathNodeID } from '../shared/breadcrumbs.service';
import { UtilsService } from '../shared/utils.service';

interface IBreadcrumb {
    label: string;

    url: string;
    isCurrent: boolean;
}

@Component({
    selector: 'breadcrumb',
    templateUrl: 'breadcrumb.component.html',
    styleUrls: ['breadcrumb.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit {
    @Input() currentNodeId: PathNodeID;

    breadcrumbs: Array<IBreadcrumb>;

    constructor(private breadcrumbsService: BreadcrumbsService, private utils: UtilsService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.breadcrumbs = [];

        if (!this.currentNodeId) {
            return;
        }

        const currentNode = this.breadcrumbsService.getPathNode(this.currentNodeId);
        let node = currentNode;
        do {
            const breadcrumb: IBreadcrumb = {
                label: node.name,
                url: node.path ? this.utils.getUrl(node.path, this.route.snapshot.params) : null,
                isCurrent: node.id === currentNode.id
            };
            this.breadcrumbs.unshift(breadcrumb);
            node = node.parent;
        }
        while (node && node.name);
    }
}
