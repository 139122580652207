export class ResetPasswordModel {
    constructor(data?: any) {
        if (data) {
            this.newPassword = data.newPassword;

            this.confirmPassword = data.confirmPassword;

            this.userId = data.userId;

            this.code = data.code;
        }
    }

    public newPassword: string;

    public confirmPassword: string;

    public userId: string;

    public code: string;

    "type"? = "ResetPasswordModel";
}
