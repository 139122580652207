﻿import { ActionPlanRagRating } from "./ActionPlanRagRating";

export class ActionPlanModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.question = data.question;

            this.answer = data.answer;

            this.status = data.status;

            this.comment = data.comment;

            this.ragRating = data.ragRating;

            this.ragRatingName = data.ragRatingName;

            this.targetDate = data.targetDate;

            this.agencyAuditorSignedByName = data.agencyAuditorSignedByName;

            this.heiAuditorSignedByName = data.heiAuditorSignedByName;

            this.attachmentPath = data.attachmentPath;

            this.heiAuditorSignedAt = data.heiAuditorSignedAt;

            this.agencyAuditorSignedAt = data.agencyAuditorSignedAt;

            this.canSign = data.canSign;

            this.signed = data.signed;
        }
    }

    public id: number;

    public question: string;

    public answer: string;

    public status: string;

    public comment: string;

    public ragRating: ActionPlanRagRating;

    public ragRatingName: string;

    public targetDate: Date;

    public agencyAuditorSignedByName: string;

    public heiAuditorSignedByName: string;

    public attachmentPath: string;

    public heiAuditorSignedAt: Date;

    public agencyAuditorSignedAt: Date;

    public canSign: boolean;

    public signed: boolean;

    "type"? = "ActionPlanModel";
}
