export class SuccessModel {
    constructor(data?: any) {
        if (data) {
            this.message = data.message;

            this.data = data.data;
        }
    }
    public message: string;

    public data: any;

    "type"? = "SuccessModel";
}
