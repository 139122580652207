import { Attribute, Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_ASYNC_VALIDATORS, Validator } from '@angular/forms';
import { HttpClientService } from '@inplace/http';
import { Observable } from 'rxjs';

const directiveName = 'asyncValidator';

@Directive({
    selector: `[${directiveName}][formControlName],[${directiveName}][formControl],[${directiveName}][ngModel]`,
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => AsyncValidatorDirective),
            multi: true
        }
    ]
})
export class AsyncValidatorDirective implements Validator {
    @Input() remoteUrl: string;
    @Input() entityId: number;

    private existsTimeout$;

    constructor(
        @Attribute(directiveName) private asyncValidator: string,
        private readonly httpClient: HttpClientService
    ) {
    }

    validate(c: AbstractControl): Promise<{ [key: string]: any }> | Observable<{ [key: string]: any }> {
        return this.validateUniquePromise(c.value);
    }

    validateUniquePromise(value: string): Promise<{ [key: string]: any }> {
        clearTimeout(this.existsTimeout$);

        return new Promise(resolve => {
            this.existsTimeout$ = setTimeout(() => {
                if (value) {
                    this.httpClient
                        .post(`${this.remoteUrl}/exists`, {
                            propertyName: this.asyncValidator,
                            value,
                            entityId: this.entityId
                        })
                        .subscribe((exists: boolean) => {
                            if (exists) {
                                resolve({ exists: true });
                            } else {
                                resolve(null);
                            }
                        });
                } else {
                    resolve(null);
                }
            }, 1000);
        });
    }
}
