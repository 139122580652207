import { BaseQuestion } from "./BaseQuestion";
import { IRangeRestriction } from "./IRangeRestriction";
import { INumericRangeQuestionRule } from "./QuestionRuleContracts";

export class RangeQuestion extends BaseQuestion implements IRangeRestriction<number>, INumericRangeQuestionRule {
    minValue: number;
    minValueDescription: string;
    maxValue: number;
    maxValueDescription: string;

    "type"? = "RangeQuestion";

    constructor(data?: any) {
        super(data);

        if (data) {
            this.minValue = data.minValue;
            this.minValueDescription = data.minValueDescription;
            this.maxValue = data.maxValue;
            this.maxValueDescription = data.maxValueDescription;
        }
    }
}
