import { UserModel } from "./UserModel";

export class AuditorModel extends UserModel {
    constructor(data?: any) {
        super(data);

        if (data) {
            this.name = data.name;

            this.phoneNumber = data.phoneNumber;
        }
    }

    public name: string;

    public phoneNumber: string;

    "type"? = "AuditorModel";
}
