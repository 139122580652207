﻿export class RequiredSignOffAudit {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.templateName = data.templateName;

            this.agency = data.agency;

            this.status = data.status;
        }
    }

    public id: number;

    public templateName: string;

    public agency: string;

    public status: string;

    "type"? = "RequiredSignOffAudit";
}
