import { UserModel } from "./UserModel";

export class AgencyAuditorModel extends UserModel {
    constructor(data?: any) {
        super(data);

        if (data) {
        }
    }

    "type"? = "AgencyAuditorModel";
}
