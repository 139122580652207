import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@inplace/http';
import { Subscription } from 'rxjs';

@Component({
    template: `
        <div class="audit-auth">
            <img src="assets/img/InPlace-Audit.png?v=1" title="InPlace" />
            <div class="loader"></div>
        </div>
    `,
    styles: [`
        .audit-auth {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding-top: 40vh;
            text-align: center;
            background-color: #eee;
        }
        .audit-auth .loader {
            border: 5px solid #eee;
            border-top: 5px solid rgb(0, 174, 239);
            border-left: 5px solid rgb(0, 174, 239);
            border-right: 5px solid rgb(0, 174, 239);
            border-radius: 50%;
            width: 42px;
            height: 42px;
            margin: auto;
            animation: spin 2s linear infinite;
            margin-top: 4px;
        }
        .audit-auth img {
            height: 80px;
            margin-bottom: 10px;
        }
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }`],
    encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit, OnDestroy {
    subscription = new Subscription();

    constructor(private authService: AuthService,
        private router: Router) { }

    ngOnInit(): void {
        if (window.location.hash) {
            this.subscription.add(this.authService.authorizedCallback().subscribe(res => {
                if (res.authenticated) {
                    this.router.navigateByUrl(res.returnRoute);
                } else {
                    console.log('Access denied');
                }
            }))
        } else {
            this.authService.authorize();
        }
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
