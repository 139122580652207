﻿import { Section } from "./Section";

export class AuditTemplateModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.name = data.name;

            this.description = data.description;

            this.validFrom = data.validFrom;

            this.validTo = data.validTo;

            this.validityPeriod = data.validityPeriod;

            this.regionId = data.regionId;

            this.disciplineIds = data.disciplineIds;

            this.statusCommand = data.statusCommand;

            this.statusName = data.statusName;

            this.sections = data.sections;

            this.createdAt = data.createdAt;

            this.createdById = data.createdById;

            this.statuses = data.statuses;

            this.guidanceFilePath = data.guidanceFilePath;

            this.auditTypeId = data.auditTypeId;

            this.heiIds = data.heiIds;
        }
    }

    public id: number;

    public name: string;

    public description: string;

    public validFrom: Date;

    public validTo: Date;

    public validityPeriod: number;

    public regionId: number;

    public disciplineIds: number[];

    public statusCommand: number;

    public statusName: string;

    public sections: Array<Section>;

    public createdAt: Date;

    public createdById: number;

    public statuses: { [key: number]: string };

    public guidanceFilePath: string;

    public auditTypeId: number;
    
    public heiIds: number[];

    "type"? = "AuditTemplateModel";
}
