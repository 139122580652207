import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'camelCaseToRegular' })
export class CamelCaseToRegularPipe implements PipeTransform {
    transform(value: any): any {
        const transformSingle = (val: string): any => {
            const transformedValue = val
                // insert a space before all caps
                .replace(/([A-Z])/g, ' $1')
                // uppercase the first character
                .replace(/^./, str => str.toUpperCase());

            return transformedValue;
        };

        if (typeof value === 'string') {
            return transformSingle(value);
        } else {
            for (let prop in value) {
                value[prop] = transformSingle(value[prop]);
            }

            return value;
        }
    }
}
