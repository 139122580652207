﻿export enum AuditStatus {
    actionPlanOpen = 1,
    completed = 2,
    expired = 3,
    inProgress = 4,
    open = 5,
    provisional = 6,
    requirementsNotMet = 7,
    scheduled = 8
}
