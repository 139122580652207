﻿export class AuditTemplateListModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.name = data.name;

            this.regionName = data.regionName;

            this.validFrom = data.validFrom;

            this.validTo = data.validTo;

            this.validOn = data.validOn;

            this.statusDisplayName = data.statusDisplayName;
        }
    }

    public id: number;

    public name: string;

    public regionName: string;

    public validFrom: Date;

    public validTo: Date;

    public validOn: string;

    public statusDisplayName: string;

    "type"? = "AuditTemplateListModel";
}
