﻿﻿import { Directive, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ToastrService } from 'ngx-toastr';

import { IMessage, NotificationMessageService } from '../core/notification-message.service';

@Directive({
    selector: 'notifications'
})
export class NotificationsDirective implements OnInit, OnDestroy {
    @ViewChild('notifications') element: ElementRef;

    constructor(
        private readonly notificationMessageService: NotificationMessageService,
        private readonly toastr: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.notificationMessageService.message$.pipe(
            untilDestroyed(this)
        ).subscribe((message: IMessage) => this.toastr[message.type](message.text, null, {}));
    }

    ngOnDestroy(): void {
    }
}
