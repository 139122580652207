import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigProvider as InPlaceAuditToolConfigProvider, ICoreConfig } from '@inplace/core';
import { AuthService } from '@inplace/http';

export interface IAuditToolConfig extends ICoreConfig {
    apiUrls: {
        audittool: string;
    };
}

@Injectable()
export class ConfigProvider {
    constructor(private configProvider: InPlaceAuditToolConfigProvider<IAuditToolConfig>,
        private authService: AuthService) { }

    load(): Promise<void> {
        return this.configProvider.load();
    }

    setHeaders(contentType: string = 'application/json', accept: string = 'application/json'): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        if (contentType != null) {
            headers = headers.append('Content-Type', contentType);
        }
        if (accept != null) {
            headers = headers.append('Accept', 'application/json');
        }

        const token = this.authService.token;
        if (token) {
            const tokenValue = 'Bearer ' + token;
            headers = headers.append('Authorization', tokenValue);
        }

        return headers;
    }

    get settings(): IAuditToolConfig {
        return this.configProvider.settings;
    }
}
