import { HttpHeaders } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FileRestrictions, SelectEvent, SuccessEvent, UploadComponent, UploadEvent } from '@progress/kendo-angular-upload';
import { ImageCroppedEvent } from 'ngx-image-cropper/src/interfaces';
import { ConfigProvider } from 'src/app/core/services/config-provider.service';
import { Constants } from '../../../app/core/constants';
import { Models } from '../../../app/models';
import { IMessage, NotificationMessageService } from '../../core/notification-message.service';
import { ModalDialogService } from '../modal-dialog.service';

@Component({
    selector: 'image-cropper-component',
    styleUrls: ['image-cropper.component.css'],
    templateUrl: 'image-cropper.component.html'
})
export class ImageCropperComponent  {
    @ViewChild(UploadComponent) uploader: UploadComponent;

    sourceFile: File;
    onSave: (result: { file: File, imagePreview: string}) => {};

    imageName: string;

    uploadRestrictions: FileRestrictions;
    readonly uploadUrl: string;
    readonly uploadHeaders: HttpHeaders;

    readonly useCredentials = false;
    readonly imageWidth;
    readonly imageHeight;

    isUploading = false;

    readonly croppingFormat = 'png';
    croppedImage64: string;
    private croppedImageBlob: Blob;
    constructor(
        private readonly modalDialogService: ModalDialogService,
        private readonly notificationMessageService: NotificationMessageService,
        private readonly configProvider: ConfigProvider
    ) {
        this.uploadRestrictions = {
            allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif', '.bmp'],
            maxFileSize: Constants.maxFileSize
        };
        this.imageWidth = Constants.imageWidth;
        this.imageHeight = Constants.imageHeight;

        this.uploadUrl = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/media`;;

        this.uploadHeaders = this.configProvider.setHeaders(null, null);
        this.uploadHeaders = this.uploadHeaders.append('enctype', 'multipart/form-data');
    }

    imageCropped(event: ImageCroppedEvent): void {
        this.croppedImage64 = event.base64;
        this.croppedImageBlob = event.file;
    }

    imageSelected(event: SelectEvent): void {
        this.sourceFile = new File([event.files[0].rawFile], event.files[0].rawFile.name, { type: event.files[0].rawFile.type });
        if (!this.uploadRestrictions.allowedExtensions.includes(event.files[0].extension.toLocaleLowerCase())) {
            const message: IMessage = {
                text: 'Please ensure correct file type is provided: .jpg, .jpeg, .png, .gif, .bmp',
                type: Models.MessageType[Models.MessageType.error]
            };
            this.notificationMessageService.message.next(message);
        } else
        if (event.files[0].size > this.uploadRestrictions.maxFileSize) {
            const message: IMessage = {
                text: 'Please ensure File Size does not exceed maximum limit (5 mb)',
                type: Models.MessageType[Models.MessageType.error]
            };
            this.notificationMessageService.message.next(message);
            this.sourceFile = null;
        }
    }

    imageCleared(): void {
        this.sourceFile = this.croppedImage64 = this.croppedImageBlob = null;
    }

    uploadHandler(event: UploadEvent): void {
        this.isUploading = true;
        const existingRawFile = event.files[0].rawFile;
        event.files[0].rawFile = new File([this.croppedImageBlob],
            existingRawFile.name.substr(0, existingRawFile.name.lastIndexOf('.') + 1) + this.croppingFormat,
            { type: this.croppedImageBlob.type });
    }

    uploadSuccess(event: SuccessEvent): void {
        this.closePopup();
    }

    uploadComplete(event: Event): void {
        this.isUploading = false;
    }

    saveImage(): void {
        if (this.croppedImage64.length > Constants.maxFileSize) {
            alert('Size of cropped image too large');

            return;
        }
        const fileName = this.sourceFile.name;
        this.onSave({
            imagePreview: this.croppedImage64,
            file: new File([this.croppedImageBlob], fileName.substr(0, fileName.lastIndexOf('.') + 1) + this.croppingFormat, { type: this.croppedImageBlob.type })
        });
        this.closePopup();
    }

    closePopup(): void {
        this.modalDialogService.closeDialog();
    }
}
