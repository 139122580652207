import { FilterField } from "./FilterField";

import { IFilter } from "./IFilter";
import { ColumnField } from "./ColumnField";

export class DataGridConfigurationModel {
    constructor(data?: any) {
        if (data) {
            this.filters = data.filters;

            this.columns = data.columns;
        }
    }

    public filters: FilterField<IFilter>[];

    public columns: ColumnField[];

    "type"? = "DataGridConfigurationModel";
}
