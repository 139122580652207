import { UserModel } from './UserModel';

export class UserDetailsModel extends UserModel {
    name: string;
    surname: string;
    titleId: number;
    phoneNumber: string;
    businessPhone: string;
    accountType: string;
    createdAt: Date;
    lastLoginAt: Date;
    lastPasswordChangeDate: Date;
    agencyName: string;
    agencyIds: Array<number>;

    'type'? = 'UserDetailsModel';

    constructor(data?: any) {
        super(data);

        if (data) {
            this.name = data.name;
            this.surname = data.surname;
            this.titleId = data.titleId;
            this.phoneNumber = data.phoneNumber;
            this.businessPhone = data.businessPhone;
            this.accountType = data.accountType;
            this.createdAt = data.createdAt;
            this.lastLoginAt = data.lastLoginAt;
            this.lastPasswordChangeDate = data.lastPasswordChangeDate;
            this.agencyName = data.agencyName;
            this.agencyIds = data.agencyIds;
        }
    }
}
