import { IRule } from "./IRule";
import { RuleType } from "./RuleType";
import { RuleRagRating } from "./RuleRagRating";

export class BaseRule implements IRule {
    constructor(data?: any) {
        if (data) {
            this.ruleType = data.ruleType;

            this.ragRating = data.ragRating;

            this.comment = data.comment;

            this.filePath = data.filePath;
        }
    }

    public ruleType: RuleType;

    public ragRating: RuleRagRating;

    public comment: string;

    public filePath: string;

    "type"? = "BaseRule";
}
