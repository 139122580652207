import { UserModel } from "./UserModel";

export class HeiAuditorModel extends UserModel {
    constructor(data?: any) {
        super(data);

        if (data) {
            this.name = data.name;
        }
    }

    public name: string;

    "type"? = "HeiAuditorModel";
}
