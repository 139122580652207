import { ISection } from "./ISection";
import { BaseQuestion } from "./BaseQuestion";

export class Section implements ISection {
    constructor(data?: any) {
        if (data) {
            this.sectionId = data.sectionId;

            this.orderId = data.orderId;

            this.title = data.title;

            this.description = data.description;

            this.questions = data.questions;
        }
    }

    public sectionId: number;

    public orderId: number;

    public title: string;

    public description: string;

    public questions: Array<BaseQuestion>;

    "type"? = "Section";
}
