export class CreateRoleModel {
    constructor(data?: any) {
        if (data) {
            this.userType = data.userType;

            this.roleName = data.roleName;
        }
    }

    public userType: number;

    public roleName: string;

    "type"? = "CreateRoleModel";
}
