// angular modules
import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { Constants } from './constants';
import { HttpObserverService } from './http-observer.service';
import { HttpResponseHandlerService } from './http-response-handler.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { NotificationMessageService } from './notification-message.service';
import { AuditToolConfigurationService } from './services/audit-tool-configuration.service';
import { ConfigProvider } from './services/config-provider.service';

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        NotificationMessageService,
        HttpResponseHandlerService,
        HttpObserverService,
        Constants,
        ConfigProvider,
        AuditToolConfigurationService
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
