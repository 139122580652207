import { BaseRule } from "./BaseRule";
import { IQuestion } from "./IQuestion";
import { QuestionType } from "./QuestionType";

export class BaseQuestion implements IQuestion {
    questionType: QuestionType;
    sectionId: number;
    questionId: number;
    orderId: number;
    title: string;
    displayText: string;
    filePath: string;
    rules: Array<BaseRule>;

    "type"? = "BaseQuestion";

    constructor(data?: any) {
        if (data) {
            this.questionType = data.questionType;
            this.sectionId = data.sectionId;
            this.questionId = data.questionId;
            this.orderId = data.orderId;
            this.title = data.title;
            this.displayText = data.displayText;
            this.filePath = data.filePath;
            this.rules = data.rules;
        }
    }
}
