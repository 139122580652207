﻿export class HeiModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.name = data.name;

            this.address = data.address;

            this.phone = data.phone;

            this.webSite = data.webSite;

            this.regionId = data.regionId;

            this.isActive = data.isActive;

            this.status = data.status;

            this.regionName = data.regionName;

            this.signaturePictureId = data.signaturePictureId;

            this.logoPictureId = data.logoPictureId;

            this.titleId = data.titleId;

            this.positionTitle = data.positionTitle;

            this.givenName = data.givenName;

            this.surName = data.surName;

            this.disciplineIds = data.disciplineIds;

            this.linkToAllAgencies = data.linkToAllAgencies;
        }
    }

    public id: number;

    public name: string;

    public address: string;

    public phone: string;

    public webSite: string;

    public regionId: number;

    public isActive: boolean;

    public status: string;

    public regionName: string;

    public signaturePictureId: string;

    public signaturePictureIndex: number;

    public logoPictureId: string;

    public logoPictureIndex: number;

    "type"? = "HeiModel";

    public titleId: string;

    public positionTitle: string;

    public givenName: string;

    public surName: string;

    public disciplineIds: number[];

    public linkToAllAgencies: boolean;

    public files: File[] = [];
}
