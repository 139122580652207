﻿import { BaseRule } from "./BaseRule";
import { IDateTimeRangeQuestionRule } from "./QuestionRuleContracts";
import { IRangeRestriction } from "./IRangeRestriction";

export class DateTimeRangeRule extends BaseRule implements IDateTimeRangeQuestionRule, IRangeRestriction<Date> {
    constructor(data?: any) {
        super(data);

        if (data) {
            this.minValue = data.minValue;

            this.maxValue = data.maxValue;
        }
    }

    public minValue: Date;

    public maxValue: Date;

    "type"? = "DateTimeRangeRule";
}
