import { Injectable } from '@angular/core';
import { ConfigProvider } from './config-provider.service';

@Injectable()
export class AuditToolConfigurationService {

    constructor(private configProvider: ConfigProvider) { }

    load(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.configProvider.load()
                .then(() => resolve())
                .catch(res => reject(res));
        });
    }
}
