export class ChangeActiveStatusModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.isActive = data.isActive;
        }
    }

    public id: string;

    public isActive: boolean;

    "type"? = "ChangeActiveStatusModel";
}
