export class NumberPostModel {
    constructor(data?: any) {
        if (data) {
            this.value = data.value;
        }
    }

    public value: number;

    "type"? = "NumberPostModel";
}
