export class UserCultureModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.name = data.name;

            this.value = data.value;
        }
    }

    public id: number;

    public name: string;

    public value: string;

    'type'? = 'UserCultureModel';
}
