import { BaseRule } from "./BaseRule";
import { IInNumberListQuestionRule } from "./QuestionRuleContracts";
import { IInListRule } from "./IInListRule";

export class InNumberListRule extends BaseRule implements IInNumberListQuestionRule, IInListRule<number> {
    
    "type"? = "InNumberListRule";
    
    constructor(data?: any) {
        super(data);

        if (data) {
            this.variants = data.variants;
        }
    }

    public variants: number[];

}
