import { BaseQuestion } from "./BaseQuestion";
import { IRangeRestriction } from "./IRangeRestriction";
import { IDateTimeRangeQuestionRule } from "./QuestionRuleContracts";

export class DateQuestion extends BaseQuestion implements IRangeRestriction<Date>, IDateTimeRangeQuestionRule {
    constructor(data?: any) {
        super(data);

        if (data) {
            this.minValue = data.minValue;

            this.maxValue = data.maxValue;
        }
    }

    public minValue: Date;

    public maxValue: Date;

    "type"? = "DateQuestion";
}
