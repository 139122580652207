﻿export enum Area {
    actionPlan = 0,
    agency = 1,
    audit = 2,
    disciplines = 3,
    hei = 4,
    regions = 5,
    roles = 6,
    template = 7,
    adminUsers = 8,
    agencyUsers = 9,
    heiUsers = 10,
    privacyManagement = 11,
    auditStatusRollback = 12
}
