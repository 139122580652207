﻿import { AgencySubmitModel } from "./AgencySubmitModel";

export class AgencyViewModel {
    "type"? = "AgencyViewModel";

    constructor(data?: any) {
        if (data) {
            this.agency = data.agency;

            this.parentAgencies = data.parentAgencies;

            this.regions = data.regions;

            this.disciplines = data.disciplines;

            this.hasOnDemandTemplates = data.hasOnDemandTemplates;
        }
    }

    public agency: AgencySubmitModel;

    public parentAgencies: { key: string; value: string; detail: string };

    public regions: { [key: number]: string };

    public disciplines: { [key: number]: string };

    public hasOnDemandTemplates: boolean;

}
