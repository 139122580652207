﻿export class CreateAgencyModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.name = data.name;

            this.parentAgencyId = data.parentAgencyId;

            this.address = data.address;

            this.regionId = data.regionId;

            this.postCode = data.postCode;

            this.phone = data.phone;

            this.website = data.website;

            this.agencyCode = data.agencyCode;

            this.heiIds = data.heiIds;

            this.disciplineIds = data.disciplineIds;
        }
    }

    public id: number;

    public name: string;

    public parentAgencyId: number;

    public address: string;

    public regionId: number;

    public postCode: string;

    public phone: string;

    public website: string;

    public agencyCode: string;

    public heiIds: number[];

    public disciplineIds: number[];

    "type"? = "CreateAgencyModel";
}
