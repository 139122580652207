﻿export class PermissionsMatrixModel {

    roleName: string;

    areas: { [key: number]: string };

    permissions: { [key: number]: string };

    matrix: { [key: number]: Array<boolean> };

    'type' ? = 'PermissionsMatrixModel';
    constructor(data?: any) {
        if (data) {
            this.roleName = data.roleName;

            this.areas = data.areas;

            this.permissions = data.permissions;

            this.matrix = data.matrix;
        }
    }
}
