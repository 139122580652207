import { AgencyModel } from "./AgencyModel";

export class AgencySubscriptionModel extends AgencyModel {
    isSubscribed: boolean;

    "type"? = "AgencySubmitModel";

    constructor(data?: any) {
        super(data);

        if (data) {
            this.isSubscribed = data.isSubscribed;
        }
    }
}
