﻿import { AnswerSection } from "./AnswerSection";

export class AuditDetailsModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.status = data.status;

            this.completedOn = data.completedOn;

            this.templateId = data.templateId;

            this.templateName = data.templateName;

            this.templateRegionName = data.templateRegionName;

            this.agencyId = data.agencyId;

            this.agencyHeis = data.agencyHeis;

            this.auditHeisDic = data.auditHeisDic;

            this.agencyName = data.agencyName;

            this.disciplines = data.disciplines;

            this.validFrom = data.validFrom;

            this.validUntil = data.validUntil;

            this.updatedAt = data.updatedAt;

            this.createdAt = data.createdAt;

            this.lastUpdatedById = data.lastUpdatedById;

            this.lastUpdatedBy = data.lastUpdatedBy;

            this.sections = data.sections;

            this.heiAuditorSignedById = data.heiAuditorSignedById;

            this.agencyAuditorSignedById = data.agencyAuditorSignedById;

            this.guidanceFilePath = data.guidanceFilePath;

            this.auditType = data.auditType;
        }
    }

    public id: number;

    public status: string;

    public completedOn: Date;

    public templateId: number;

    public templateName: string;

    public templateRegionName: string;

    public agencyId: string;

    public agencyHeis: { [key: number]: string };

    public auditHeisDic: { [key: number]: string };

    public agencyName: string;

    public disciplines: string[];

    public validFrom: Date;

    public validUntil: Date;

    public updatedAt: Date;

    public createdAt: Date;

    public lastUpdatedById: number;

    public lastUpdatedBy: string;

    public sections: Array<AnswerSection>;

    public heiAuditorSignedById: string;

    public agencyAuditorSignedById: string;

    public guidanceFilePath: string;

    public auditType: string;

    "type"? = "AuditDetailsModel";
}
