import { RoleModel } from "./RoleModel";

export class ChangeUserRoleModel {
    constructor(data?: any) {
        if (data) {
            this.userId = data.userId;

            this.roles = data.roles;
        }
    }

    public userId: string;

    public roles: Array<RoleModel>;

    "type"? = "ChangeUserRoleModel";
}
