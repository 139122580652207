﻿import { ActionPlanModel } from "./ActionPlanModel";

export class ActionPlanPreviewModel {
    constructor(data?: any) {
        if (data) {
            this.actionPlans = data.actionPlans;

            this.status = data.status;

            this.coutRatingLow = data.coutRatingLow;

            this.coutRatingMedium = data.coutRatingMedium;

            this.coutRatingHigh = data.coutRatingHigh;

            this.coutRatingDevelopmentRequired = data.coutRatingDevelopmentRequired;
        }
    }

    public actionPlans: ActionPlanModel[];

    public status: string;

    public coutRatingLow: number;

    public coutRatingMedium: number;

    public coutRatingHigh: number;

    public coutRatingDevelopmentRequired: number;

    "type"? = "ActionPlanPreviewModel";
}
