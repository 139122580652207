import { Attribute, Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

const directiveName = 'validateNumberRange';

@Directive({
    selector: `[${directiveName}][formControlName],[${directiveName}][formControl],[${directiveName}][ngModel]`,
    providers: [
        {
            provide: NG_VALIDATORS, useExisting:
                forwardRef(() => NumberRangeValidatorDirective),
            multi: true
        }
    ]
})
export class NumberRangeValidatorDirective implements Validator {
    constructor(
        @Attribute(directiveName) public validateRange: string,
        @Attribute('numberRangeMin') public numberRangeMin: string,
        @Attribute('numberRangeMax') public numberRangeMax: string
    ) { }

    validate(c: AbstractControl): { [index: string]: any; } {
        const value: number = +c.value;

        if (this.numberRangeMin) {
            const minValueControl: AbstractControl = c.parent.get(this.numberRangeMin);

            if (minValueControl && minValueControl.value) {

                if (value < +minValueControl.value) {
                    return {
                        validateNumberRange: false
                    };
                } else {
                    if (minValueControl.errors && minValueControl.errors.hasOwnProperty(directiveName)) {
                        delete minValueControl.errors[directiveName];
                        if (!Object.keys(minValueControl.errors).length) {
                            minValueControl.setErrors(null);
                        }
                    }
                }
            }
        } else if (this.numberRangeMax) {
            const maxValueControl: AbstractControl = c.parent.get(this.numberRangeMax);

            if (maxValueControl && maxValueControl.value) {

                if (+value > +maxValueControl.value) {
                    return {
                        validateNumberRange: false
                    };
                } else {
                    if (maxValueControl.errors && maxValueControl.errors.hasOwnProperty(directiveName)) {
                        delete maxValueControl.errors[directiveName];
                        if (!Object.keys(maxValueControl.errors).length) {
                            maxValueControl.setErrors(null);
                        }
                    }
                }
            }
        }

        return null;
    }
}
