﻿export class ActionPlanFileModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.attachmentPath = data.attachmentPath;
        }
    }

    public id: number;

    public attachmentPath: string;

    "type"? = "ActionPlanFileModel";
}
