﻿export class DisciplineApiModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.name = data.name;
        }
    }

    public id: number;

    public name: string;

    "type"? = "DisciplineApiModel";
}
