import { UserRegistrationModel } from "./UserRegistrationModel";

export class AgencyUserRegistrationModel extends UserRegistrationModel {
    "type"? = "AgencyUserRegistrationModel";
    
    constructor(data?: any) {
        super(data);

        if (data) {
            this.agencyIds = data.agencyIds;
        }
    }

    public agencyIds: number[];

}
