import { BaseQuestion } from "./BaseQuestion";

import { IValidateble } from "./IValidateble";

export class Answer<T extends BaseQuestion> implements IValidateble {
    question: T;
    value: string;
    comment: string;

    "type"? = "Answer";

    constructor(data?: any) {
        if (data) {
            this.question = data.question;
            this.value = data.value;
            this.comment = data.comment;
        }
    }
}
