import { UserRegistrationModel } from "./UserRegistrationModel";

export class AdminUserRegistrationModel extends UserRegistrationModel {
    constructor(data?: any) {
        super(data);

        if (data) {
        }
    }

    "type"? = "AdminUserRegistrationModel";
}
 