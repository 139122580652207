﻿export class RoleModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.name = data.name;

            this.userTypeName = data.userTypeName;

            this.usersCount = data.usersCount;
        }
    }

    public id: number;

    public name: string;

    public userTypeName: string;

    public usersCount: number;

    "type"? = "RoleModel";
}
