import { Injectable } from '@angular/core';
import { HttpClientService } from '@inplace/http';
import { Observable, of } from 'rxjs';

import { ConfigProvider } from '../core/services/config-provider.service';
import { Models } from '../models';

@Injectable()
export class ConfigurationService {
    private options: Models.ClientOptions;
    private fileUploadOptions: {
        maxAllowedSize: number;
    };

    constructor(
        private readonly httpClient: HttpClientService,
        private readonly configProvider: ConfigProvider
    ) {
        this.getOptions()
            .subscribe((response: Models.ClientOptions) => this.options = response);
        this.getFileuploadOptions()
            .subscribe((response: { maxAllowedSize: number }) =>
                this.fileUploadOptions = response);
    }

    get clientOptions(): Observable<Models.ClientOptions> {
        return this.getOptions();
    }

    getFileuploadOptions(): Observable<{ maxAllowedSize: number }> {
        if (this.fileUploadOptions) {
            return of(this.fileUploadOptions);
        } else {
            const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/configuration/fileUploadOptions`;
            return this.httpClient.get<{ maxAllowedSize: number }>(url);
        }
    }

    private getOptions(): Observable<Models.ClientOptions> {
        if (this.options) {
            return of(this.options);
        } else {
            const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/configuration`;
            return this.httpClient.get<Models.ClientOptions>(url);
        }
    }
}
