import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FileRestrictions, UploadEvent } from '@progress/kendo-angular-upload';
import { map } from 'rxjs/operators';

import { UploadService } from './upload.service';

@Component({
    selector: 'upload',
    templateUrl: 'upload.component.html'
})
export class UploadComponent implements OnInit, OnChanges {
    @Input() fileRestricitions: FileRestrictions;
    @Input() urlToUpload: string;
    @Input() urlToDownload: string;
    @Input() filePath: string;
    @Input() controlsEnabled: boolean;
    @Input() fileUploadId: number;
    @Output() readonly filePathChange = new EventEmitter<string>();

    fileName: string;

    constructor(
        private readonly uploadService: UploadService
    ) {
    }

    ngOnInit(): void {
        if (this.filePath) {
            this.fileName = this.filePath.split('/').pop();
        }
    }

    ngOnChanges(changes: any): void {
        if (changes.filePath && changes.filePath.currentValue) {
            this.fileName = changes.filePath.currentValue.split('/').pop();
        }
    }

    uploadEventHandler(event: UploadEvent): void {
        if (event.files.length > 0) {
            const file = event.files[0];

            const formData = new FormData();
            formData.append('uploadFile', file.rawFile, file.name);
            if (this.fileUploadId) {
                formData.append('id', this.fileUploadId.toString());
            }
            this.uploadService.uploadFile(this.urlToUpload, formData).pipe(
                map((response: string) => {
                    if (this.filePath) {
                        this.uploadService.removeFile(this.urlToDownload, this.filePath, this.fileUploadId);
                    }

                    this.filePath = response;
                    this.filePathChange.emit(this.filePath);
                    this.fileName = file.name;
                })
            ).subscribe();
        }
    }

    downloadFile(): void {
        const params = new HttpParams({
            fromObject: {
                filePath: this.filePath
            }
        });

        this.uploadService.downloadFile(this.urlToDownload, params, this.fileName);
    }

    removeFile(): void {
        this.uploadService.removeFile(this.urlToDownload, this.filePath, this.fileUploadId);
        this.filePath = '';
        this.fileName = '';
        this.filePathChange.emit(this.filePath);
    }
}
