import { Injectable } from '@angular/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';

@Injectable()
export class ModalDialogService {
    private dialog: DialogRef;

    private confirmAction: () => void;
    private denyAction: () => void;

    constructor(private dialogService: DialogService) { }

    get $closeDialogSubscriber(): Observable<DialogCloseResult> {
        if (this.dialog) {
            return this.dialog.result;
        }

        return null;
    }

    openDialog(component: any, inputProperties: any, title?: string): void {
        this.dialog = this.dialogService.open({
            title,
            content: component
        });

        const componentInfo = this.dialog.content.instance;

        Object.keys(inputProperties).forEach(property => {
            componentInfo[property] = inputProperties[property];
        });
    }

    openConfirmDialog(text: string, onConfirm: () => void, onDeny?: () => void): void {
        this.confirmAction = onConfirm;
        this.denyAction = onDeny;

        this.dialog = this.dialogService.open({
            title: 'Please confirm',
            content: text,
            actions: [
                { text: 'No', confirmed: false },
                { text: 'Yes', primary: true, confirmed: true }
            ]
        });

        this.dialog.result.subscribe(result => {
            let isConfirmed: boolean;
            isConfirmed = result instanceof DialogCloseResult ? false : (result as any).confirmed;

            if (isConfirmed) {
                this.confirmAction();
            } else if (onDeny) {
                this.denyAction();
            }
        });
    }

    closeDialog(): void {
        this.dialog.close();
    }
}
