﻿import { HeiModel } from './HeiModel';

export class HeiDetailsModel extends HeiModel {

    regions: { [key: number]: string };
    titles: { [key: number]: string };
    disciplines: { [key: number]: string };

    'type' ? = 'HeiDetailsModel';
    constructor(data?: any) {
        super(data);

        if (data) {
            this.regions = data.regions;
            this.titles = data.titles;
            this.disciplines = data.disciplines;
        }
    }
}
