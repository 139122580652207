﻿import { BaseRule } from "./BaseRule";
import { ILessThanRule } from "./ILessThanRule";

export class LessThanRule extends BaseRule implements ILessThanRule {
    constructor(data?: any) {
        super(data);

        if (data) {
            this.valueToCompare = data.valueToCompare;
        }
    }

    public valueToCompare: number;

    "type"? = "LessThanRule";
}
