﻿import { BaseQuestion } from "./BaseQuestion";

export class BoolQuestion extends BaseQuestion {
    constructor(data?: any) {
        super(data);

        if (data) {
            this.trueValueDescription = data.trueValueDescription;

            this.falseValueDescription = data.falseValueDescription;
        }
    }

    public trueValueDescription: string;

    public falseValueDescription: string;

    "type"? = "BoolQuestion";
}
