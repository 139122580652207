import { UserType } from './UserType';

export class UserDetailsSubmitModel {
    userId: string;
    userType: UserType;
    name: string;
    surname: string;
    phoneNumber: string;
    businessPhone: string;
    titleId: number;
    email: string;
    fullName: string;
    accountType: string;
    createdAt: Date;
    lastLoginAt: Date;
    lastPasswordChangeDate: Date;
    roleName: string;
    roleId: string;
    userName: string;
    status: string;
    cultureId: number;
    isActive: boolean;
    agencies: { [key: number]: string };
    agencyIds: Array<number>;
    heiName: string;
    heiId: number;
    receiveNotification: boolean;

    'type'?= 'UserDetailsSubmitModel';

    constructor(data?: any) {
        if (data) {
            this.userId = data.userId;
            this.userType = data.userType;
            this.name = data.name;
            this.surname = data.surname;
            this.phoneNumber = data.phoneNumber;
            this.businessPhone = data.businessPhone;
            this.titleId = data.titleId;
            this.email = data.email;
            this.fullName = data.fullName;
            this.accountType = data.accountType;
            this.createdAt = data.createdAt;
            this.lastLoginAt = data.lastLoginAt;
            this.lastPasswordChangeDate = data.lastPasswordChangeDate;
            this.roleName = data.roleName;
            this.roleId = data.roleId;
            this.userName = data.userName;
            this.status = data.status;
            this.cultureId = data.cultureId;
            this.isActive = data.isActive;
            this.agencies = data.agencies;
            this.agencyIds = data.agencyIds;
            this.heiName = data.heiName;
            this.heiId = data.heiId;
            this.receiveNotification = data.receiveNotification;
        }
    }
}
