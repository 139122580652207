import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClientService } from '@inplace/http';
import { Observable, Subscription, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthTokenService } from '../auth/auth-token.service';
import { Constants } from '../core/constants';
import { HttpResponseHandlerService } from '../core/http-response-handler.service';
import { ConfigProvider } from '../core/services/config-provider.service';
import { Models } from '../models';
import { CommandResult } from '@inplace/core';

export interface ChangePasswordModel {
    userId: string;
}

@Injectable()
export class UserService {
    constructor(
        private readonly httpClient: HttpClientService,
        private readonly authTokenService: AuthTokenService,
        private readonly constants: Constants,
        private readonly httpResponseHandlerService: HttpResponseHandlerService,
        private readonly configProvider: ConfigProvider
    ) {
    }

    createUserRole(userRoleModel: Models.CreateRoleModel): Observable<any> {
        const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/roles`;
        return this.httpClient.post(url, userRoleModel);
    }

    forgotPassword(forgotPasswordModel: Models.ForgotPasswordRequestModel): Subscription {
        const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/user/forgotPassword`;
        return this.httpClient.post(url, forgotPasswordModel)
            .subscribe((response: Models.SuccessModel) => {
                this.httpResponseHandlerService.handleSuccess(response, this.constants.angularRoutes.loginUrl);
            }, error => {
                this.httpResponseHandlerService.handleError(error);

                return throwError(error);
            });
    }
    resetPassword(userId: string): Observable<any> {
        const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/user/changePassword`;
        return this.httpClient.post(url, { userId } as ChangePasswordModel);
    }

    roles(userType?: Models.UserType): Observable<Array<Models.RoleModel>> {
        let params = new HttpParams();
        if (userType) {
            params = params.append(this.constants.routeParameters.userType, userType.toString());
        }
        const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/roles/list`;
        return this.httpClient.get<Array<Models.RoleModel>>(url, params);
    }

    createUser(userRegistrationModel: Models.UserRegistrationModel): Observable<object> {
        const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/user`;
        return this.httpClient.post(url, userRegistrationModel).pipe(
            tap((response: Models.SuccessModel) => this.httpResponseHandlerService.handleSuccess(response))
        );
    }

    updateUserDetails(userModel: Models.UserDetailsSubmitModel): Observable<any> {
        const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/user`;
        return this.httpClient.put(url, userModel).pipe(
            tap((response: CommandResult) => {
                if (response.isSuccess) {
                    const message = { message: 'User was updated successfully' } as Models.SuccessModel;
                    this.httpResponseHandlerService.handleSuccess(message)
                }

                if (!response.isSuccess || response.hasWarnings) {
                    const messages = response.warnings.concat(response.errors);
                    const message = { message: messages.join(',') } as Models.SuccessModel;
                    this.httpResponseHandlerService.handleSuccess(message, null, Models.MessageType.warning)
                }
            })
        );
    }

    getUser(userId: string): Observable<Models.UserViewModel> {
        const params = new HttpParams({
            fromObject: {
                [this.constants.routeParameters.userId]: userId
            }
        });
        const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/user`;
        return this.httpClient.get<Models.UserViewModel>(url, params);
    }

    changeActiveStatus(itemId: string, isActive: boolean): Observable<object> {
        const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/user/changeItemStatus`;
        return this.httpClient.post(url, { id: itemId, isActive });
    }

    getUserTypes(): Observable<{ [key: number]: string }> {
        const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/user/userTypes`;
        return this.httpClient.get<{ [key: number]: string }>(url);
    }

    manageAgencySubscriptions(model: Models.ManageSubscriptionsModel): Observable<object> {
        const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/user/agency/subscriptions`;
        return this.httpClient.post(url, model);
    }

    importHeiUsers(importHeiUsersModel: Models.ImportHeiUsersModel): Observable<object> {
        const url = `${this.configProvider.settings.apiUrls.audittool.trimEnd('/')}/api/user/importHeiUsers`;
        return this.httpClient.post(url, importHeiUsersModel).pipe(
            tap((response: Models.SuccessModel) => this.httpResponseHandlerService.handleSuccess(response))
        );
    }
}
