import { BaseRule } from "./BaseRule";

import { IEqualsStringQuestionRule } from "./QuestionRuleContracts";
import { IEqualsRule } from "./IEqualsRule";

export class EqualsStringRule extends BaseRule implements IEqualsStringQuestionRule, IEqualsRule<string> {
    constructor(data?: any) {
        super(data);

        if (data) {
            this.valueToCompare = data.valueToCompare;
        }
    }

    public valueToCompare: string;

    "type"? = "EqualsStringRule";
}
