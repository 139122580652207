﻿import { BaseRule } from "./BaseRule";
import { INumericRangeQuestionRule } from "./QuestionRuleContracts";
import { IRangeRestriction } from "./IRangeRestriction";

export class NumericRangeRule extends BaseRule implements INumericRangeQuestionRule, IRangeRestriction<number> {
    constructor(data?: any) {
        super(data);

        if (data) {
            this.minValue = data.minValue;

            this.maxValue = data.maxValue;
        }
    }

    public minValue: number;

    public maxValue: number;

    "type"? = "NumericRangeRule";
}
