import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class HttpObserverService {
    executionSubject: Subject<boolean> = new Subject();
    isExecuting: Observable<boolean>;

    private requestsCount = 0;

    constructor(private router: Router) {
        this.isExecuting = this.executionSubject.asObservable();

        this.router.events.pipe(
            filter(e => e instanceof NavigationStart)
        ).subscribe(() => this.requestsCount = 0);
    }

    requestSent(): void {
        if (this.requestsCount === 0) {
            this.executionSubject.next(true);
        }

        this.requestsCount++;
    }

    responseReceived(): void {
        if (this.requestsCount === 0) {
            return;
        }

        this.requestsCount--;

        if (this.requestsCount === 0) {
            this.executionSubject.next(false);
        }
    }
}
