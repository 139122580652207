import { Component, ViewEncapsulation } from '@angular/core';

import { AuthService } from '../auth/auth.service';
import { Constants } from '../core/constants';

@Component({
    selector: '[navigation-menu]',
    templateUrl: 'navigation-menu.component.html',
    styleUrls: ['navigation-menu.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NavigationMenuComponent {
    constructor(
        private authService: AuthService,
        readonly constants: Constants
    ) {
    }

    logOff(): void {
        this.authService.logout();
    }
}
