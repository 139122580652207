import { Injectable } from '@angular/core';
import { Params, PRIMARY_OUTLET, Router } from '@angular/router';
import * as moment from 'moment';

import { AuthService } from '../auth/auth.service';
import { Constants } from '../core/constants';

@Injectable()
export class UtilsService {
    constructor(
        private router: Router,
        private authService: AuthService,
        private constants: Constants
    ) {
    }

    getEnumValues(enumType: any, transform?: (value: string) => string): { [key: number]: string; } {
        const enumValues: { [key: number]: string; } = {};
        const options: Array<number> = Object.keys(enumType).map<number>(k => parseInt(k));
        options.slice(0, options.length / 2).map(k => enumValues[k] = transform ? transform(enumType[k]) : enumType[k]);

        return enumValues;
    }

    enumValuesToDictionary(values: Array<number>, enumType: any): { [key: number]: string; } {
        if (values && values.length) {
            const enumValues: { [key: number]: string; } = {};
            values.forEach(v => enumValues[v] = enumType[v]);

            return enumValues;
        }

        return null;
    }

    isValidDate(input: string): boolean {
        return moment(input, moment.ISO_8601, true).isValid();
    }

    toLocalDateString(date: moment.MomentInput, format?: string): string {
        return moment.utc(date).local().format(format);
    }

    toUtcString(date: moment.MomentInput, format?: string): string {
        return moment.utc(date).format(format);
    }

    toCroppedLocalDate(date: moment.MomentInput): Date {
        const mom = moment(date);
        const offset = mom.utcOffset();

        return mom.subtract(offset, 'minutes').toDate();
    }

    fromCroppedLocalDate(date: moment.MomentInput): Date {
        const mom = moment(date);
        const offset = mom.utcOffset();

        return mom.add(offset, 'minutes').toDate();
    }

    getUrl(path: string, params: Params): string {
        const parsedUrl = this.router.parseUrl(path);

        const segments = parsedUrl.root.children[PRIMARY_OUTLET].segments;
        for (const segment of segments) {
            if (segment.path.indexOf(':') === 0) {
                const parName = segment.path.substring(1);
                segment.path = params[parName];
            }
        }

        return this.router.serializeUrl(parsedUrl);
    }

    isEmpty(obj: any): boolean {
        return Object.keys(obj).length === 0;
    }

    getCurrentUserDateFormat(): string {
        const culture = this.authService.getCurrentUserCulture();

        return culture === 'en-US' ? this.constants.dateFormats.mdyDate : this.constants.dateFormats.dmyDate;
    }
}
