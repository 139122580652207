﻿export class AgencyModel {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.name = data.name;

            this.parentAgencyName = data.parentAgencyName;

            this.parentAgencyId = data.parentAgencyId;

            this.regionName = data.regionName;

            this.postCode = data.postCode;

            this.auditsCount = data.auditsCount;

            this.isActive = data.isActive;

            this.status = data.status;

            this.agencyCode = data.agencyCode;
        }
    }

    public id: number;

    public name: string;

    public parentAgencyName: string;

    public parentAgencyId: number;

    public regionName: string;

    public postCode: string;

    public auditsCount: number;

    public isActive: boolean;

    public status: string;

    public agencyCode: string;

    "type"? = "AgencyModel";
}
