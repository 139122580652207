import { AgencySubscriptionModel } from "./AgencySubscriptionModel";

export class ManageSubscriptionsModel {
    userId: string;
    subscriptionAgencies: Array<AgencySubscriptionModel>;

    "type"? = "ManageSubscriptionsModel";

    constructor(data?: any) {
        if (data) {
            this.userId = data.userId;
            this.subscriptionAgencies = data.subscriptionAgencies;
        }
    }
}
