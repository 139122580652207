export class AuthTokenModel {
    constructor(data?: any) {
        if (data) {
            this.access_token = data.access_token;

            this.refresh_token = data.refresh_token;

            this.id_token = data.id_token;

            this.expires_in = data.expires_in;

            this.token_type = data.token_type;

            this.expiration_date = data.expiration_date;
        }
    }

    public access_token: string;

    public refresh_token: string;

    public id_token: string;

    public expires_in: number;

    public token_type: string;

    public expiration_date: Date;

    "type"? = "AuthTokenModel";
}
