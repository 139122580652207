import { Injectable } from '@angular/core';

import { Constants } from '../core/constants';

@Injectable()
export class BreadcrumbsService {
    private root: IPathNode;

    private nodesSearch: Array<IPathNode> = [];

    constructor(private constants: Constants) {
        this.root = {
            name: null,
            id: PathNodeID.root,
            path: null,
            parent: null,
            children: [
                {
                    name: 'Agency',
                    id: PathNodeID.agencies,
                    path: null,
                    parent: this.root,
                    children: [
                        {
                            name: 'Agencies',
                            id: PathNodeID.agencyList,
                            path: Constants.angularRoutes.staffAgenciesPath,
                            parent: null,
                            children: [
                                {
                                    name: 'Agency Details',
                                    id: PathNodeID.agencyDetails,
                                    path: Constants.angularRoutes.staffAgencyDetailsPath,
                                    parent: null,
                                    children: [
                                        {
                                            name: 'Add Users',
                                            id: PathNodeID.agencyAddAuditors,
                                            path: Constants.angularRoutes.staffAddAuditorToAgencyPath,
                                            parent: null,
                                            children: null
                                        },
                                        {
                                            name: 'Add HEIs',
                                            id: PathNodeID.agencyAddHeis,
                                            path: Constants.angularRoutes.staffAddAuditorToHeiPath,
                                            parent: null,
                                            children: null
                                        }]
                                },
                                {
                                    name: 'Create Agency',
                                    id: PathNodeID.agencyCreate,
                                    path: Constants.angularRoutes.staffAgencyCreatePath,
                                    parent: null,
                                    children: null
                                }
                            ]
                        },
                        {
                            name: 'Users',
                            id: PathNodeID.agencyAuditors,
                            path: Constants.angularRoutes.staffAgencyAuditorsPath,
                            parent: null,
                            children: null
                        }
                    ]
                },
                {
                    name: 'HEI',
                    id: PathNodeID.hei,
                    path: null,
                    parent: this.root,
                    children: [
                        {
                            name: 'HEIs',
                            id: PathNodeID.heiList,
                            path: Constants.angularRoutes.staffHeiPath,
                            parent: null,
                            children: [
                                {
                                    name: 'HEI Details',
                                    id: PathNodeID.heiDetails,
                                    path: Constants.angularRoutes.staffHeiDetailsPath,
                                    parent: null,
                                    children: null
                                },
                                {
                                    name: 'Create HEI',
                                    id: PathNodeID.heiCreate,
                                    path: Constants.angularRoutes.staffCreateHeiPath,
                                    parent: null,
                                    children: null
                                }
                            ]
                        },
                        {
                            name: 'Users',
                            id: PathNodeID.heiAuditors,
                            path: Constants.angularRoutes.staffHeiAuditorsPath,
                            parent: null,
                            children: null
                        }
                    ]
                },
                {
                    name: 'Admin',
                    id: PathNodeID.admin,
                    path: null,
                    parent: null,
                    children: [
                        {
                            name: 'Disciplines',
                            id: PathNodeID.disciplineList,
                            path: Constants.angularRoutes.adminDisciplineListPath,
                            parent: null,
                            children: null
                        },
                        {
                            name: 'Regions',
                            id: PathNodeID.regionList,
                            path: Constants.angularRoutes.adminRegionListPath,
                            parent: null,
                            children: null
                        },
                        {
                            name: 'Users',
                            id: PathNodeID.userList,
                            path: Constants.angularRoutes.adminUserPath,
                            parent: null,
                            children: [
                                {
                                    name: 'User Details',
                                    id: PathNodeID.userDetails,
                                    path: Constants.angularRoutes.userDetailsPath,
                                    parent: null,
                                    children: []
                                },
                                {
                                    name: 'Create User',
                                    id: PathNodeID.userCreate,
                                    path: Constants.angularRoutes.adminUserCreatePath,
                                    parent: null,
                                    children: null
                                },
                                {
                                    name: 'Import Hei Users',
                                    id: PathNodeID.importHeiUsers,
                                    path: Constants.angularRoutes.adminImportHeiUsersPath,
                                    parent: null,
                                    children: null
                                }
                            ]
                        },
                        {
                            name: 'Roles',
                            id: PathNodeID.roleList,
                            path: Constants.angularRoutes.adminRolesPath,
                            parent: null,
                            children: [
                                {
                                    name: 'Add Role',
                                    id: PathNodeID.roleAdd,
                                    path: null,
                                    parent: null,
                                    children: null
                                },
                                {
                                    name: 'Role Details',
                                    id: PathNodeID.roleDetails,
                                    path: Constants.angularRoutes.adminRolePermissionsPath,
                                    parent: null,
                                    children: null
                                }
                            ]
                        },
                        {
                            name: 'Privacy Management',
                            id: PathNodeID.privacyManagement,
                            path: Constants.angularRoutes.adminPrivacyManagementPath,
                            parent: null,
                            children: null
                        }
                    ]
                },
                {
                    name: 'Audit',
                    id: PathNodeID.audit,
                    path: null,
                    parent: null,
                    children: [
                        {
                            name: 'Audits',
                            id: PathNodeID.auditList,
                            path: Constants.angularRoutes.staffAuditsPath,
                            parent: null,
                            children: [
                                {
                                    name: 'Audit Details',
                                    id: PathNodeID.auditDetails,
                                    path: Constants.angularRoutes.staffAuditDetailsPath,
                                    parent: null,
                                    children: [
                                        {
                                            name: 'Action Plan',
                                            id: PathNodeID.actionPlan,
                                            path: Constants.angularRoutes.staffActionPlanDetails,
                                            parent: null,
                                            children: null
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            name: 'Upcoming Audits',
                            id: PathNodeID.auditUpcomingList,
                            path: Constants.angularRoutes.staffAuditsUpcomingPath,
                            parent: null,
                            children: null
                        },
                        {
                            name: 'Audit Status Dashboard',
                            id: PathNodeID.auditReports,
                            path: Constants.angularRoutes.staffAuditReportPath,
                            parent: null,
                            children: null
                        },
                        {
                            name: 'Templates',
                            id: PathNodeID.templateList,
                            path: Constants.angularRoutes.staffAuditTemplatePath,
                            parent: null,
                            children: null
                        },
                        {
                            name: 'Template Builder',
                            id: PathNodeID.templateBuilder,
                            path: Constants.angularRoutes.createTemplatePath,
                            parent: null,
                            children: null
                        }
                    ]
                },
                {
                    name: 'My Details',
                    id: PathNodeID.myDetails,
                    path: Constants.angularRoutes.currentUserDetailsPath,
                    parent: null,
                    children: null
                }
            ]
        };

        this.setParents(this.root);
    }

    getPathNode(id: PathNodeID): IPathNode {
        return this.nodesSearch[id];
    }

    private setParents(node: IPathNode, parent: IPathNode = null): void {
        node.parent = parent;
        this.nodesSearch[node.id] = node;

        if (node.children) {
            for (const n of node.children) {
                this.setParents(n, node);
            }
        }
    }
}

export interface IPathNode {
    id: PathNodeID;
    name: string;
    path: string;
    parent: IPathNode;
    children: Array<IPathNode>;
}

export enum PathNodeID {
    root,
    agencies,
    agencyList,
    agencyAuditors,
    agencyCreate,
    agencyDetails,
    agencyAddAuditors,
    agencyAddHeis,
    actionPlan,
    hei,
    heiList,
    heiAuditors,
    heiCreate,
    heiDetails,
    admin,
    userList,
    privacyManagement,
    userDetails,
    userCreate,
    importHeiUsers,
    regionList,
    disciplineList,
    audit,
    auditList,
    auditUpcomingList,
    auditDetails,
    templateBuilder,
    templateList,
    roleList,
    roleAdd,
    roleDetails,
    myDetails,
    auditReports
}
