export class PermissionItemModel {
    constructor(data?: any) {
        if (data) {
            this.areaId = data.areaId;

            this.permissionId = data.permissionId;

            this.roleId = data.roleId;
        }
    }

    public areaId: number;

    public permissionId: number;

    public roleId: string;

    "type"? = "PermissionItemModel";
}
