import { Injectable } from '@angular/core';

@Injectable()
export class Constants {
    static readonly maxFileSize = 5 * 1024 * 1024;
    static readonly imageWidth = 227;
    static readonly imageHeight = 95;
    static angularRoutes: IAngularRoutes = {
        dashboardPath: 'dashboard',
        loginPath: 'login',

        adminUserPath: 'admin/users',
        adminRolesPath: 'admin/roles',
        adminRolePermissionsPath: 'admin/permissions/:roleId',
        adminPrivacyManagementPath: 'admin/privacy-management',
        adminUserCreatePath: 'admin/users/create',
        adminImportHeiUsersPath: 'admin/users/import-hei-users',
        staffAuditTemplatePath: 'audit-templates',
        adminRegionListPath: 'admin/region/list',
        adminRegionDetailsPath: 'admin/region/details',
        adminDisciplineListPath: 'admin/discipline/list',
        adminDisciplineDetailsPath: 'admin/discipline/details',
        createTemplatePath: 'template-builder',
        editTemplatePath: 'template-builder/:id',
        staffAuditsPath: 'staff/audits',
        staffAuditsUpcomingPath: 'staff/upcoming-audits',
        staffAuditDetailsPath: 'staff/audit/:auditId',
        staffHeiPath: 'staff/heis',
        staffCreateHeiPath: 'staff/hei/create',
        staffActionPlanDetails: 'audit/action-plans/:auditId',
        staffAgenciesPath: 'staff/agencies',
        staffAgencyAuditorsPath: 'staff/agency/auditors',
        staffAddAuditorToAgencyPath: 'staff/agency/add-auditor/:agencyId',
        staffAddHeiToAgencyPath: 'staff/agency/add-hei/:agencyId',
        staffAgencyCreatePath: 'staff/agencies/create',
        staffHeiDetailsPath: 'staff/hei/:heiId',
        staffAddAuditorToHeiPath: 'staff/hei/add-auditor/:heiId',
        staffAgencyDetailsPath: 'staff/agency/:agencyId',
        currentUserDetailsPath: 'user',
        userDetailsPath: 'user/:userId',
        changeUserRolePath: 'change-role/:userId',
        forgotPasswordPath: 'forgot-password',
        forgotPasswordConfirmedPath: 'forgot-password-confirmed',
        resetPasswordSuccessfullPath: 'reset-password-successfull',
        resetPasswordPath: 'reset-password/:userId',
        resetPasswordInvalidLinkPath: 'incorrect-reset-password-link',
        internalServerErrorPath: 'error-500',
        notFoundErrorPath: 'error-404',
        forbidErrorPath: 'error-403',
        staffAuditReportPath: 'staff/audit-reports',


        dashboardUrl: '/dashboard',
        loginUrl: '/login',

        adminUsersUrl: '/admin/users',
        adminRolesUrl: '/admin/roles',
        adminRolePermissionsUrl: '/admin/permissions',
        adminPrivacyManagementUrl: '/admin/privacy-management',
        adminUserCreateUrl: '/admin/users/create',
        auditActionPlanDetailsUrl: '/audit/action-plans',
        staffAuditTemplateUrl: '/audit-templates',
        adminRegionListUrl: '/admin/region/list',
        adminDisciplineListUrl: '/admin/discipline/list',
        templateBuilderUrl: '/template-builder',
        staffAuditsUrl: '/staff/audits',
        staffAuditsUpcomingUrl: '/staff/upcoming-audits',
        staffAuditDetailsUrl: '/staff/audit',
        staffHeiUrl: '/staff/heis',
        staffCreateHeiPathUrl: '/staff/hei/create',
        staffHeiAuditorsPath: 'staff/hei/auditors',
        staffHeiDetailsUrl: '/staff/hei',
        staffAgencyAuditorsUrl: '/staff/agency/auditors',
        staffAddAuditorToAgencyUrl: '/staff/agency/add-auditor',
        staffAddHeiToAgencyUrl: '/staff/agency/add-hei',
        staffAddAuditorToHeiPathUrl: '/staff/hei/add-auditor',
        staffAgencyCreatePathUrl: '/staff/agencies/create',
        staffHeiAuditorsUrl: '/staff/hei/auditors',
        staffAgenciesUrl: '/staff/agencies',
        staffAgencyDetailsUrl: '/staff/agency',
        staffAuditReportsUrl: '/staff/audit-reports',

        userDetailsUrl: '/user',
        changeUserRoleUrl: '/change-role',
        forgotPasswordUrl: '/forgot-password',
        forgotPasswordConfirmedUrl: '/forgot-password-confirmed',
        resetPasswordSuccessfullUrl: '/reset-password-successfull',
        internalServerErrorUrl: '/error-500',
        notFoundErrorPathUrl: '/error-404',
        forbidErrorPageUrl: '/error-403'
    };

    static breadcrumbs: IBreadcrumbs = {
        agencyList: 'Agencies List',
        auditList: 'Audits List',
        auditCreate: 'Create Audit',
        userList: 'Users List',
        userDetails: 'User Details',
        heiList: 'HEIs List',
        heiDetails: 'HEI Details',
        agencyDetails: 'Agency Details',
        auditTemplatesList: 'Templates List',
        auditorsList: 'Auditors',
        changeUserRole: 'Change UserRole',
        createHEI: 'Create HEI',
        createAgency: 'Create Agency',
        createUser: 'Create User',
        addUser: 'Add Auditor',
        createDisciplines: 'Create Disciplines',
        createRegions: 'Create Regions'
    };

    static menuItems: IMenuItems = {
        createHEI: 'Create HEI',
        createAgency: 'Create Agency',
        createUser: 'Create User',
        createTemplate: 'Create Template',
        addUser: 'Add User',
        createDisciplines: 'Create Disciplines',
        createRegions: 'Create Region',
        addHei: 'Add HEI',
        addAgency: 'Add Agency',
        addLogo: 'Add Logo',
        createRole: 'Create Role',
        importHeiUsers: "Import HEI Users"
    };

    auth: IAuthConst = {
        tokenKey: 'token',
        refreshTokenKey: 'refresh_token',
        expiresInKey: 'expires_in',
        adminRoleName: 'admin',
        cultureCookieName: '.AspNetCore.Culture',
        redirectUrl: 'redirectUrl',
        grantTypes: {
            password: 'password',
            refreshToken: 'refresh_token'
        },
        tokenProperties: {
            role: 'role',
            name: 'name',
            id: 'sub'
        }
    };

    routeParameters: IRouteParameters = {
        userId: 'userId',
        code: 'code',
        agencyId: 'agencyId',
        auditId: 'auditId',
        heiId: 'heiId',
        skip: 'skip',
        take: 'take',
        breadcrumb: 'breadcrumb',
        id: 'id',
        auditorId: 'auditorId',
        assigned: 'assigned',
        userType: 'userType',
        templateId: 'templateId',
        roleId: 'roleId',
        monthsToExpire: 'monthsToExpire',
        name: 'name',
        value: 'value',
        limit: 'limit',
        regionId: 'regionId',
        auditTypeId: 'auditTypeId',
        disciplineIds: 'disciplineIds'
    };

    angularRoutes = Constants.angularRoutes;

    appTitle = 'InPlace Audit';

    navigationMenuItems: INavigationMenuItems = {
        userDetails: 'My Details',
        logOut: 'Logout'
    };

    tempDataKeys: ITempDataKeys = {
        agencyId: 'AgencyId',
        agencyName: 'AgencyName',
        regionId: 'RegionIds',
        disciplineId: 'DisciplineIds'
    };

    dateFormats: IDateFormats = {
        shortDate: 'YYYY-MM-DD',
        dmyDate: 'dd/MM/yyyy',
        mdyDate: 'M/d/yyyy',
        time24h: 'HH:mm'
    };

    auditTemplateValidityPeriods = [
        {text: 'Manually Scheduled', value: 0},
        {text: '6', value: 6},
        {text: '12', value: 12},
        {text: '18', value: 18},
        {text: '24', value: 24},
        {text: '36', value: 36},
        {text: '48', value: 48}
    ];

    auditTypes = [
        {text: 'Highest Level Agency Audit', value: 1},
        {text: 'On Demand Agency Audit', value: 2},
        {text: 'Lowest Level Agency Audit', value: 3}
    ];
}

export interface IAuthConst {
    tokenKey: string;
    refreshTokenKey: string;
    expiresInKey: string;
    adminRoleName: string;
    cultureCookieName: string;
    redirectUrl: string;
    grantTypes: IGrantTypeConst;
    tokenProperties: IAuthTokenProperties;
}

export interface IGrantTypeConst {
    password: string;
    refreshToken: string;
}

export interface IRouteParameters {
    userId: string;
    code: string;
    agencyId: string;
    auditId: string;
    heiId: string;
    skip: string;
    take: string;
    breadcrumb: string;
    id: string;
    auditorId: string;
    assigned: string;
    userType: string;
    templateId: string;
    roleId: string;
    monthsToExpire: string;
    name: string;
    value: string;
    limit: string;
    regionId: string;
    auditTypeId: string;
    disciplineIds: string;
}

export interface IAngularRoutes {
    dashboardPath: string;
    loginPath: string;

    adminUserPath: string;
    adminUserCreatePath: string;
    adminImportHeiUsersPath: string;
    adminRolesPath: string;
    adminRolePermissionsPath: string;
    adminPrivacyManagementPath: string;
    adminDisciplineListPath: string;
    adminDisciplineDetailsPath: string;
    adminRegionListPath: string;
    adminRegionDetailsPath: string;
    staffAuditTemplatePath: string;
    createTemplatePath: string;
    editTemplatePath: string;
    staffAuditsPath: string;
    staffAuditsUpcomingPath: string;
    staffAuditDetailsPath: string;
    staffActionPlanDetails: string;
    staffCreateHeiPath: string;
    staffHeiPath: string;
    staffHeiDetailsPath: string;
    staffAddAuditorToHeiPath: string;
    staffAgenciesUrl: string;
    staffAgenciesPath: string;
    staffAgencyAuditorsPath: string;
    staffAddAuditorToAgencyPath: string;
    staffAddHeiToAgencyPath: string;
    staffAgencyDetailsPath: string;
    staffAgencyCreatePath: string;
    staffAuditReportPath: string;

    currentUserDetailsPath: string;
    userDetailsPath: string;
    changeUserRolePath: string;
    forgotPasswordPath: string;
    forgotPasswordConfirmedPath: string;
    resetPasswordSuccessfullPath: string;
    resetPasswordPath: string;
    resetPasswordInvalidLinkPath: string;
    internalServerErrorPath: string;
    notFoundErrorPath: string;
    forbidErrorPath: string;

    dashboardUrl: string;
    loginUrl: string;

    adminUsersUrl: string;
    adminUserCreateUrl: string;
    adminRolesUrl: string;
    adminRolePermissionsUrl: string;
    adminPrivacyManagementUrl: string;
    adminDisciplineListUrl: string;
    adminRegionListUrl: string;
    staffAuditTemplateUrl: string;
    templateBuilderUrl: string;
    staffAuditsUrl: string;
    staffAuditsUpcomingUrl: string;
    staffAuditDetailsUrl: string;
    staffHeiUrl: string;
    staffCreateHeiPathUrl: string;
    staffHeiAuditorsPath: string;
    staffHeiDetailsUrl: string;
    staffAddAuditorToHeiPathUrl: string;
    staffHeiAuditorsUrl: string;
    staffAgencyAuditorsUrl: string;
    auditActionPlanDetailsUrl: string;
    staffAddAuditorToAgencyUrl: string;
    staffAddHeiToAgencyUrl: string;
    staffAgencyDetailsUrl: string;
    staffAgencyCreatePathUrl: string;
    staffAuditReportsUrl: string;

    userDetailsUrl: string;
    changeUserRoleUrl: string;
    forgotPasswordUrl: string;
    forgotPasswordConfirmedUrl: string;
    resetPasswordSuccessfullUrl: string;
    internalServerErrorUrl: string;
    notFoundErrorPathUrl: string;
    forbidErrorPageUrl: string;
}

export interface INavigationMenuItems {
    userDetails: string;
    logOut: string;
}

export interface ITempDataKeys {
    agencyId: string;
    agencyName: string;
    regionId: string;
    disciplineId: string;
}

export interface IBreadcrumbs {
    agencyList: string;
    auditList: string;
    userList: string;
    userDetails: string;
    heiList: string;
    heiDetails: string;
    agencyDetails: string;
    auditTemplatesList: string;
    auditorsList: string;
    changeUserRole: string;
    createHEI: string;
    createAgency: string;
    createUser: string;
    addUser: string;
    createDisciplines: string;
    createRegions: string;
    auditCreate: string;
}

export interface IMenuItems {
    createHEI: string;
    createAgency: string;
    createUser: string;
    createTemplate: string;
    addUser: string;
    createDisciplines: string;
    createRegions: string;
    addHei: string;
    addAgency: string;
    addLogo: string;
    createRole: string;
    importHeiUsers: string;
}

export interface IAuthTokenProperties {
    name: string;
    role: string;
    id: string;
}

export interface IDateFormats {
    shortDate: string;
    dmyDate: string;
    mdyDate: string;
    time24h: string;
}
