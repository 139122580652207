﻿export class AddAuditorToHeiModel {
    'type'? = 'AddAuditorToHeiModel';

    constructor(data?: any) {
        if (data) {
            this.id = data.id;

            this.fullName = data.fullName;

            this.roleId = data.roleId;

            this.roleName = data.roleName;
        }
    }

    public id: string;

    public fullName: string;

    public roleId: string;

    public roleName: string;

}
