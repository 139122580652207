export class ImportHeiUsersModel {
    'type'? = 'ImportHeiUsersModel';

    public heiId: string;
    public roleId: string;
    public importFilePath: string;

    constructor(data?: any) {
        if (data) {
            this.heiId = data.heiId;
            this.roleId = data.roleId;
            this.importFilePath = data.importFilePath;
        }
    }
}
