import { UserType } from './UserType';

export class UserModel {
    userId: string;
    userType: UserType;
    userTypeName: string;
    userName: string;
    fullName: string;
    email: string;
    isActive: boolean;
    roleName: string;
    roleId: string;
    agencies: { [key: number]: string };
    heiName: string;
    heiId: number;
    status: string;
    cultureId: number;

    'type'? = 'UserModel';

    constructor(data?: any) {
        if (data) {
            this.userId = data.userId;
            this.userType = data.userType;
            this.userTypeName = data.userTypeName;
            this.userName = data.userName;
            this.fullName = data.fullName;
            this.email = data.email;
            this.isActive = data.isActive;
            this.roleName = data.roleName;
            this.roleId = data.roleId;
            this.agencies = data.agencies;
            this.heiName = data.heiName;
            this.heiId = data.heiId;
            this.status = data.status;
            this.cultureId = data.cultureId;
        }
    }
}
