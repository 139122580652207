import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpObserverService } from './http-observer.service';
import { HttpResponseHandlerService } from './http-response-handler.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(
        private readonly httpObserverService: HttpObserverService,
        private readonly httpResponceHandlerService: HttpResponseHandlerService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.httpObserverService.requestSent();

        return next.handle(req).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    this.httpObserverService.responseReceived();
                }
            }),
            catchError((error: HttpErrorResponse) => {
                this.httpObserverService.responseReceived();

                return this.httpResponceHandlerService.handleError(error);
            })
        );
    }
}
