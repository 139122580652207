﻿import { BaseQuestion } from "./BaseQuestion";

import { IContainsTermQuestionRule, IEqualsStringQuestionRule } from "./QuestionRuleContracts";

export class FreeTextQuestion extends BaseQuestion implements IContainsTermQuestionRule, IEqualsStringQuestionRule {
    constructor(data?: any) {
        super(data);

        if (data) {
        }
    }

    "type"? = "FreeTextQuestion";
}
